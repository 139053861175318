/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Core bootstrap nav styles overrides.
 */

.nav.nav-tabs {
  margin-bottom: $ssp-navtabs-margin-bottom;
  margin-top: $ssp-navtabs-margin-top;

  > li {
    padding: 0;

    > a {
      font-size: $ssp-navtabs-desktop-font-size;
      line-height: $ssp-navtabs-desktop-line-height;
      padding: $ssp-navtabs-desktop-padding;
    }

    &.active {
      > a {
        background-color: $ssp-navtabs-active-background-color;
        font-weight: $ssp-navtabs-active-font-weight;
      }
    }
  }

  @include for-phone-only() {
    > li {
      > a {
        font-size: $ssp-navtabs-mobile-font-size;
        line-height: $ssp-navtabs-mobile-line-height;
        padding: $ssp-navtabs-mobile-padding;
      }
    }
  }
}

.nav-pills {
  > li {

    // Links rendered as pills
    > a {
      color: $ssp-navpills-default-color;
    }
  }
}
