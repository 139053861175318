/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

@mixin icon ($icon) {
  content: $icon;
  font-family: 'feather';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-transform: none;
}

@mixin hover {
  &:active,
  &:focus,
  &:hover {
    @content;
  }
}

@mixin webform-full-width-fields {
  // Following fields always should have full width.
  &.webform-component-checkboxes,
  &.webform-component-radios,
  &.webform-component-date,
  &.webform-component-time,
  &.webform-component-grid,
  &.webform-component-markup,
  &.webform-component-textarea {
    @content;
  }
}

@mixin multi-options-container($wrapper: '.content', $labelSelector: 'label', $inputSelector: 'input',) {
  margin-bottom: $wb-multi-option-spacing-bottom;
  margin-top: $wb-multi-option-spacing-top;
  position: relative;

  #{$wrapper} {
    padding: 0;

    > #{$labelSelector} {
      cursor: pointer;
      font-size: $wb-multi-option-font-size;
      font-weight: $wb-multi-option-font-weight;
      line-height: $wb-multi-option-line-height;
      margin: 0;
      padding-left: $wb-multi-option-label-left-spacing;
      position: relative;

      &:hover {
        &::after {
          border-color: $wb-checkbox-border-color-hover;
        }
      }
    }

    > #{$inputSelector} {
      opacity: 0;
      // In order to override styles when wrapped in .form-inline element.
      // Bootstrap style with higher specificity adds position: relative styles.
      // This needs to be overriden and hence we use `!important` tag here.
      position: absolute !important;

      /* stylelint-disable max-nesting-depth, selector-max-compound-selectors*/
      &:checked {
        + #{$labelSelector} {
          &::before {
            opacity: 1;
          }
        }
      }
      /* stylelint-enable max-nesting-depth, selector-max-compound-selectors*/
    }
  }

  & + & {
    margin-top: $wb-multi-option-option-top-adjustment;
  }
}

@mixin multi-options-container-checkbox($wrapper: '.content', $labelSelector: 'label', $inputSelector: 'input') {
  #{$wrapper} {
    > #{$inputSelector} {
      /* stylelint-disable max-nesting-depth, selector-max-compound-selectors*/
      &:checked {
        + #{$labelSelector} {
          &::after {
            background: $wb-checkbox-active-color;
            border-color: $wb-checkbox-active-color;
          }
        }
      }
      /* stylelint-enable max-nesting-depth, selector-max-compound-selectors*/
    }

    > #{$labelSelector} {
      /* stylelint-disable max-nesting-depth */
      &::before {
        border-color: $wb-checkbox-active-checkmark-color;
        border-style: solid;
        border-width: $wb-checkbox-active-checkmark-border $wb-checkbox-active-checkmark-border 0 0;
        content: '';
        display: block;
        height: $wb-checkbox-active-checkmark-height;
        left: $wb-checkbox-active-checkmark-left-offset;
        opacity: 0;
        position: absolute;
        top: 45%;
        transform: translate(0, -50%) rotate(129deg) skew(-5deg, 0deg);
        transform-origin: center;
        width: $wb-checkbox-active-checkmark-width;
        z-index: 1;
      }

      &::after {
        background: $wb-checkbox-background-color;
        border: $wb-checkbox-border-width solid $wb-checkbox-border-color;
        border-radius: $wb-checkbox-border-radius;
        content: '';
        height: $wb-checkbox-container-dimensions;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: $wb-checkbox-container-dimensions;
      }
      /* stylelint-enable max-nesting-depth*/
    }
  }
}

@mixin multi-options-container-radio($wrapper: '.content', $labelSelector: 'label', $inputSelector: 'input') {
  #{$wrapper} {
    > #{$inputSelector} {
      /* stylelint-disable max-nesting-depth, selector-max-compound-selectors*/
      &:checked {
        + #{$labelSelector} {
          &::after {
            border-color: $wb-radio-border-color-active;
          }
        }
      }
      /* stylelint-enable max-nesting-depth, selector-max-compound-selectors*/
    }

    > #{$labelSelector} {
      /* stylelint-disable max-nesting-depth */
      &::before {
        background: $wb-radio-dot-color-active;
        border-radius: 100%;
        content: '';
        display: block;
        height: $wb-radio-dot-dimensions;
        left: $wb-radio-dot-left-offset;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: $wb-radio-dot-dimensions;
        z-index: 1;
      }

      &::after {
        background: $wb-radio-background-color;
        border: $wb-radio-border-width solid $wb-radio-border-color;
        border-radius: 50%;
        content: '';
        height: $wb-radio-item-dimesions;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: $wb-radio-item-dimesions;
      }
      /* stylelint-enable max-nesting-depth*/
    }
  }
}

@mixin fa-icon($icon: '', $size: $font-size-base) {
  content: $icon;
  font-family: FontAwesome;
  font-size: $size;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  text-rendering: auto;
}

// Copy styles of alert info block.
@mixin alert-block() {
  border: 1px solid transparent;
  border-radius: $alert-border-radius;
  margin-bottom: $line-height-computed;
  padding: $alert-padding;

  // Headings for larger alerts
  h4 {
    color: inherit; // Specified for the h4 to prevent conflicts of changing $headings-color
    margin-top: 0;
  }

  // Provide class for links that match alerts
  .alert-link {
    font-weight: $alert-link-font-weight;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}

@mixin wb-label() {
  color: $wb-label-font-color;
  font-size: $wb-label-font-size;
  font-weight: $wb-label-font-weight;
  line-height: $wb-label-line-height;
  margin-bottom: $wb-label-bottom-margin;
}
