/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * CiviCRM general elements overrides.
 */

/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable selector-max-id, max-nesting-depth, selector-max-compound-selectors, selector-no-qualifying-type*/

#crm-container {
  line-height: inherit;
}

div.crm-container {
  line-height: inherit;

  table {
    font-size: inherit;
  }

  h3 {
    @extend .h3;
  }

  a {
    &,
    &:visited,
    &:link {
      @extend a;
    }

    &:focus {
      @extend a:focus;
    }

    &:hover {
      @extend a:hover;
    }
  }

  label {
    display: inline-block;
  }

  .messages {
    @extend .alert;
  }

  .status {
    @extend .alert-warning;
  }

  .help {
    @extend .alert;
    @extend .alert-success;
  }

  .crm-inline-error,
  .crm-error {
    &:not(.messages):not(.crm-error-label) {
      display: inline-block;
      font-size: $civicrm-inline-error-font-size;
      font-weight: $civicrm-inline-error-font-weight;
      padding: $civicrm-inline-error-padding 0;
    }

    &.crm-error-label {
      padding: 0;
    }

    @include alert-variant(transparent, $alert-danger-border, $alert-danger-text);

  }

  .description {
    color: inherit;
  }

  fieldset {
    border: 0;

    legend {
      font-size: ($font-size-base * 1.5);
      margin-bottom: $civicrm-form-legend-bottom-margin;
      padding: 0;
    }
  }

  .header-dark {
    background: transparent;
    padding: 0;

    @extend .h3;
  }

  .blockUI {
    &.blockOverlay {
      @extend .ssp-ajax-loader;
      background-color: $ssp-spinner-backdrop-color !important;
      background-image: none !important;
      opacity: 1 !important;

      &::before {
        content: '';
        display: block;
        @extend .ssp-spinner-border;
        @extend .text-primary;
      }
    }
  }
}
