/**
 * @file
 *
 * Contains Font famliy declarations
 */

@font-face {
  font-family: 'Miller';
  font-weight: normal;
  src:  url('../fonts/Miller-Regular.woff2') format('woff2'),
    url('../fonts/Miller-Regular.woff') format('woff'),
    url('../fonts/Miller-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Miller';
  font-weight: bold;
  src:  url('../fonts/Miller-Bold.woff2') format('woff2'),
    url('../fonts/Miller-Bold.woff') format('woff'),
    url('../fonts/Miller-Bold.ttf') format('truetype');
}
