/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.modal-content {
  // line-height: 0;
  overflow: hidden;

  iframe {
    border: 0;
  }

  .close {
    font-size: 0;
    height: $ssp-modal-close-button-size;
    opacity: $ssp-modal-close-button-opacity;
    position: absolute;
    right: $ssp-modal-close-button-positioning-right;
    top: $ssp-modal-close-button-positioning-top;
    width: $ssp-modal-close-button-size;

    &::before,
    &::after {
      background-color: $ssp-modal-close-button-icon-border-color;
      content: '';
      height: $ssp-modal-close-button-icon-border-length;
      left: 50%;
      position: absolute;
      // Top adjustment = half of the difference between button size and border length
      top: ($ssp-modal-close-button-size - $ssp-modal-close-button-icon-border-length) / 2;
      width: $ssp-modal-close-button-icon-border-width;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  .ssp-hide-from-display {
    display: none;
    height: 0;
  }

  .ssp-iframe-loader {
    align-items: center;
    display: flex;
    font-size: $ssp-modal-iframe-loader-font-size;
    height: $ssp-modal-iframe-loader-height;
    justify-content: center;
    width: 100%;
  }
}

.modal-dialog {
  margin: $ssp-modal-dialog-box-offset-y-sm auto;

  @media (min-width: $screen-sm-min) {
    margin: $ssp-modal-dialog-box-offset-y-md auto;
  }
}

.modal-body {
  background-color: $ssp-modal-body-bg-color;
}

.modal-header {
  background-color: $ssp-modal-header-bg-color;
}

.modal-footer {
  background-color: $ssp-modal-footer-bg-color;

  .btn {
    padding: $ssp-modal-footer-button-padding;
  }
}

.ssp-close-outer-modal {
  min-width: $ssp-modal-footer-close-button-min-width;
  @include button-variant($ssp-modal-footer-close-button-color, $ssp-modal-footer-close-button-background-color, $ssp-modal-footer-close-button-border-color);
}

// Fix the close icon of the error messages.
.modal-content .alert-dismissible .close {
  position: relative;
  right: unset;
  top: unset;
}
