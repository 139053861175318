/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/* stylelint-disable selector-max-id */
// Contains civicrm overrides for review form
#crm-container {
  &.crm-public {
    .ssp-details-page-section__sub-heading {
      // Override these styles to use BS styles.
      .label {
        display: inline;
        font-size: $ssp-label-font-size !important;
        font-weight: $ssp-label-font-weight;
        padding: $ssp-label-padding !important;
      }
    }

    .ssp-form-control-description {
      margin-bottom: $ssp-bootstrap-form-control-bottom-description-bottom-margin;
    }

    a.pull-left {
      margin: $civicrm-awards-button-margin-spacing;
    }

    button.crm-button_qf_AwardReview_next {
      margin: $civicrm-awards-button-margin-spacing;
    }

  }
}
