/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * CiviCRM Drupal specifics overrides.
 */

/* stylelint-disable selector-max-compound-selectors */
div.crm-container {
  // Override the CiviCRM labels in the Drupal content region.
  .region-content & {
    .crm-section {
      .label {
        color: inherit;
        font-size: inherit;
        font-weight: $ssp-font-weight-bold;
        padding-left: unset;
        text-align: left;
      }
    }
  }
}
