/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$wb-black:              #000 !default;
$wb-white:              #fff !default;
$wb-gray-200:           lighten($wb-black, 93.5%) !default; // #eee
$wb-gray-300:           #d5d5d5 !default;
$wb-gray-400:           #ced4da !default;
$wb-gray-500:           #999 !default;
$wb-gray-600:           lighten($wb-black, 46.7%) !default; // #777
$wb-gray-700:           lighten($wb-black, 33.5%) !default; // #555
$wb-gray-800:           lighten($wb-black, 20%) !default; // #333
$wb-gray-900:           lighten($wb-black, 13.5%) !default; // #222

$gray-darker:           $wb-gray-900 !default;
$gray-dark:             $wb-gray-800 !default;
$gray:                  $wb-gray-700 !default;
$gray-medium:           $wb-gray-600 !default;
$gray-light:            $wb-gray-500 !default;
$gray-lighter:          $wb-gray-300 !default;
$gray-lightest:         $wb-gray-200 !default;

$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

$wb-brand-primary-dark-100:              darken($brand-primary, 15%) !default;
$wb-brand-primary-dark-200:              #0e3d67 !default;
$wb-brand-success-dark-200:              #229022 !default;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               $gray-lightest !default;
//** Global text color on `<body>`.
$text-color:            $gray-dark !default;

//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      $wb-brand-primary-dark-100 !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, 'Times New Roman', Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

$wb-font-weight-normal:   400 !default;
$wb-font-weight-medium:   500 !default;
$wb-font-weight-bold:     700 !default;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    $wb-font-weight-medium !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.

// [converter] If $bootstrap-sass-asset-helper if used, provide path relative to the assets load path.
// [converter] This is because some asset helpers, such as Sprockets, do not work with file-relative paths.
$icon-font-path: if($bootstrap-sass-asset-helper, 'bootstrap/', '../fonts/bootstrap/') !default;

//** File name for all font files.
$icon-font-name:          'glyphicons-halflings-regular' !default;
//** Element ID within SVG icon file.
$icon-font-svg-id:        'glyphicons_halflingsregular' !default;

$wb-icon-size-default:    24px !default;


//== Animations
//
//## Define available animations here

$wb-animation-spin: spin 1s linear infinite !default;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     6px !default;
$padding-base-horizontal:   12px !default;

$padding-large-vertical:    10px !default;
$padding-large-horizontal:  16px !default;

$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;

$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;

$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;

$border-radius-base:        4px !default;
$border-radius-large:       6px !default;
$border-radius-small:       2px !default;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    $wb-white !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $brand-primary !default;

//** Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base:          4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large:         5px !default;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px !default;

//** Default background color used for all tables.
$table-bg:                      transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent:               #f9f9f9 !default;
//** Background color used for `.table-hover`.
$table-bg-hover:                #f5f5f5 !default;
$table-bg-active:               $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color:            #ddd !default;

//** Other table alignment
$wb-table-options-text-alignment: center !default;
$wb-table-options-in-one-line:    true !default;
$wb-table-options-margin:         0 !default;
$wb-table-heading-bottom-border-width: 1px !default;
$wb-table-top-border-width:       1px !default;
$wb-table-top-border-color:       #ddd !default;
$wb-table-bottom-border-width:    $wb-table-top-border-width !default;
$wb-table-bottom-border-color:    $wb-table-top-border-color !default;
$wb-table-heading-padding:        $table-cell-padding !default;
$wb-table-heading-background-color: inherit !default;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                $wb-font-weight-normal !default;

$btn-default-color:              $gray-dark !default;
$btn-default-bg:                 $wb-white !default;
$btn-default-border:             #ccc !default;

$btn-primary-color:              $wb-white !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             darken($btn-primary-bg, 5%) !default;

$btn-success-color:              $wb-white !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;

$btn-info-color:                 $wb-white !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                darken($btn-info-bg, 5%) !default;

$btn-warning-color:              $wb-white !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               $wb-white !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color:        $gray-medium !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base !default;
$btn-border-radius-large:        $border-radius-large !default;
$btn-border-radius-small:        $border-radius-small !default;

// For buttons with icons (e.g. loading spinner icon)
$wb-btn-icon-spacing:               9px !default;
$wb-btn-icon-animation:             $wb-animation-spin !default;
$wb-btn-min-width:                  unset !default;
$wb-btn-text-casing:                none !default;
$wb-btn-focus-outline:              0 !default;
$wb-btn-focus-outline-offset:       0 !default;

// File type buttons
$wb-file-upload-button-text-casing: none !default;
$wb-file-upload-button-text-color:  -internal-light-dark($wb-black, $wb-white) !default;
$wb-file-upload-button-background-color: -internal-light-dark(#efefef, #3b3b3b) !default;
$wb-file-upload-button-font-weight: inherit !default;
$wb-file-upload-button-padding:     1px 6px !default;
$wb-file-upload-button-border:      2px outset -internal-light-dark(#767676, #858585) !default;

//== Forms
//
//##

//** Default `label' variables
$wb-label-font-size:             $font-size-small !default;
$wb-label-font-weight:           $wb-font-weight-normal !default;
$wb-label-font-color:            inherit !default;
$wb-label-bottom-margin:         4px !default;
$wb-label-line-height:           20px !default;

//** `<input>` background color
$input-bg:                       $wb-white !default;
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lightest !default;

//** Text color for `<input>`s
$input-color:                    $gray !default;
//** `<input>` border color
$input-border:                   $gray-light !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            $border-radius-small !default;
//** Large `.form-control` border radius
$input-border-radius-large:      $border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small:      $border-radius-small !default;

//** Border color for inputs on focus
$input-border-focus:             #66afe9 !default;

//** Placeholder text color
$input-color-placeholder:        $gray-light !default;

//** Default `.form-control' variables
$wb-input-padding-x:             $padding-base-horizontal !default;
$wb-input-padding-y:             $padding-base-vertical !default;
$wb-input-font-size:             $font-size-base !default;
$wb-input-line-height:           $line-height-base !default;
$wb-input-border-disabled:       $input-border !default;
$wb-form-control-inline-margin:  0 !default;
$wb-form-control-inline-padding: 0 !default;
$wb-form-control-box-shadow:     inset 0 1px 1px rgba(0, 0, 0, 0.075) !default;
$wb-form-control-transition:     border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s !default;
$wb-form-control-required-color: $brand-danger !default;
$wb-form-submit-padding:         $wb-input-padding-y $wb-input-padding-x !default;
$wb-form-submit-border-radius:   $border-radius-base !default;
$wb-form-submit-previous-icon:   '\e843' !default;
$wb-form-submit-next-icon:       '\e844' !default;
$wb-form-submit-icon-spacing-x:  5px !default;
$wb-form-submit-icon-size:       16px !default;
$wb-form-help-text-color:         lighten($text-color, 25%) !default;
$wb-form-help-text-popup-padding: 0 0 0 40px !default;

$wb-form-select-dropdown-icon-spacing-right: $wb-input-padding-x !default;
$wb-form-select-dropdown-icon-background: $input-bg url('../images/dropdown-icon.svg') calc(100% - #{$wb-form-select-dropdown-icon-spacing-right}) center no-repeat !default;
$wb-form-select-dropdown-icon-background-size: 8px !default;

//** Default `.form-control` height
$input-height-base:              (floor(($wb-input-font-size * $wb-input-line-height)) + ($wb-input-padding-y * 2) + 2) !default;
//** Large `.form-control` height
$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
//** Small `.form-control` height
$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;

//** `.form-group` margin
$form-group-margin-bottom:       15px !default;

//** Default 'multi-options' font specifics
$wb-multi-option-font-size:      $font-size-base !default;
$wb-multi-option-line-height:    $line-height-base !default;
$wb-multi-option-spacing-top:    0 !default;
$wb-multi-option-spacing-bottom: $padding-large-vertical !default;
$wb-multi-option-font-weight:    $wb-font-weight-normal !default;
$wb-multi-option-label-left-spacing: 20px !default;
$wb-multi-option-option-top-adjustment: -5px !default;

$legend-color:                   $gray-dark !default;
$legend-border-color:            #e5e5e5 !default;

//** Background color for textual input addons
$input-group-addon-bg:           $gray-lightest !default;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border !default;

//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed !default;

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg:                    $wb-white !default;
//** Dropdown menu `border-color`.
$dropdown-border:                rgba($wb-black, 0.15) !default;
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border:       #ccc !default;
//** Divider color for between dropdown items.
$dropdown-divider-bg:            #e5e5e5 !default;

//** Dropdown link text color.
$dropdown-link-color:            $gray-dark !default;
//** Hover color for dropdown links.
$dropdown-link-hover-color:      darken($gray-dark, 5%) !default;
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         #f5f5f5 !default;

//** Active dropdown menu item text color.
$dropdown-link-active-color:     $component-active-color !default;
//** Active dropdown menu item background color.
$dropdown-link-active-bg:        $component-active-bg !default;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   $gray-medium !default;

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray-medium !default;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color:           $wb-black !default;


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-modal-background:  1040 !default;
$zindex-modal:             1050 !default;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;


//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    50px !default;
$navbar-margin-bottom:             $line-height-computed !default;
$navbar-border-radius:             $border-radius-base !default;
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;
$navbar-collapse-max-height:       340px !default;

$navbar-default-color:             $gray-medium !default;
$navbar-default-bg:                #f8f8f8 !default;
$navbar-default-border:            darken($navbar-default-bg, 6.5%) !default;

// Navbar links
$navbar-default-link-color:                $gray-medium !default;
$navbar-default-link-hover-color:          $gray-dark !default;
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-active-color:         $gray !default;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color !default;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%) !default;
$navbar-default-brand-hover-bg:            transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd !default;
$navbar-default-toggle-icon-bar-bg:        #888 !default;
$navbar-default-toggle-border-color:       #ddd !default;


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%) !default;
$navbar-inverse-bg:                         $gray-darker !default;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%) !default;

// Inverted navbar links
$navbar-inverse-link-color:                 lighten($gray-light, 15%) !default;
$navbar-inverse-link-hover-color:           $wb-white !default;
$navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-link-disabled-color:        #444 !default;
$navbar-inverse-link-disabled-bg:           transparent !default;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color:          $wb-white !default;
$navbar-inverse-brand-hover-bg:             transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            $gray-dark !default;
$navbar-inverse-toggle-icon-bar-bg:         $wb-white !default;
$navbar-inverse-toggle-border-color:        $gray-dark !default;


//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding:                          10px 15px !default;
$nav-link-hover-bg:                         $gray-lightest !default;

$nav-disabled-link-color:                   $gray-medium !default;
$nav-disabled-link-hover-color:             $gray-medium !default;

//== Tabs
$nav-tabs-border-color:                     #ddd !default;

$nav-tabs-link-hover-border-color:          $gray-lightest !default;

$nav-tabs-active-link-hover-bg:             $body-bg !default;
$nav-tabs-active-link-hover-color:          $gray !default;
$nav-tabs-active-link-hover-border-color:   #ddd !default;

$nav-tabs-justified-link-border-color:            #ddd !default;
$nav-tabs-justified-active-link-border-color:     $body-bg !default;

//== Pills
$nav-pills-border-radius:                   $border-radius-base !default;
$nav-pills-active-link-hover-bg:            $component-active-bg !default;
$nav-pills-active-link-hover-color:         $component-active-color !default;

//== Page header
$wb-page-header-margin:                     ($line-height-computed * 2) 0 $line-height-computed !default;
$wb-page-header-padding:                    0 0 (($line-height-computed / 2) - 1) 0 !default;

//== Pagination
//
//##

$pagination-color:                     $link-color !default;
$pagination-bg:                        $wb-white !default;
$pagination-border:                    #ddd !default;

$pagination-hover-color:               $link-hover-color !default;
$pagination-hover-bg:                  $gray-lightest !default;
$pagination-hover-border:              #ddd !default;

$pagination-active-color:              $wb-white !default;
$pagination-active-bg:                 $brand-primary !default;
$pagination-active-border:             $brand-primary !default;

$pagination-disabled-color:            $gray-medium !default;
$pagination-disabled-bg:               $wb-white !default;
$pagination-disabled-border:           #ddd !default;


//== Pager
//
//##

$pager-bg:                             $pagination-bg !default;
$pager-border:                         $pagination-border !default;
$pager-border-radius:                  15px !default;

$pager-hover-bg:                       $pagination-hover-bg !default;

$pager-active-bg:                      $pagination-active-bg !default;
$pager-active-color:                   $pagination-active-color !default;

$pager-disabled-color:                 $pagination-disabled-color !default;


//== Jumbotron
//
//##

$jumbotron-padding:              30px !default;
$jumbotron-color:                inherit !default;
$jumbotron-bg:                   $gray-lightest !default;
$jumbotron-heading-color:        inherit !default;
$jumbotron-font-size:            ceil(($font-size-base * 1.5)) !default;
$jumbotron-heading-font-size:    ceil(($font-size-base * 4.5)) !default;


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;


//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width:           200px !default;
//** Tooltip text color
$tooltip-color:               $wb-white !default;
//** Tooltip background color
$tooltip-bg:                  $wb-black !default;
$tooltip-opacity:             0.9 !default;

//** Tooltip arrow width
$tooltip-arrow-width:         5px !default;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg !default;


//== Popovers
//
//##

//** Popover body background color
$popover-bg:                          $wb-white !default;
//** Popover maximum width
$popover-max-width:                   276px !default;
//** Popover border color
$popover-border-color:                rgba($wb-black, 0.2) !default;
//** Popover fallback border color
$popover-fallback-border-color:       #ccc !default;

//** Popover title background color
$popover-title-bg:                    darken($popover-bg, 3%) !default;

//** Popover arrow width
$popover-arrow-width:                 10px !default;
//** Popover arrow color
$popover-arrow-color:                 $popover-bg !default;

//** Popover outer arrow width
$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
//** Popover outer arrow color
$popover-arrow-outer-color:           fade_in($popover-border-color, 0.05) !default;
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%) !default;

$wb-popover-title-font-family:        $headings-font-family !default;
$wb-popover-content-help-block-color: $wb-form-help-text-color !default;

//== Labels
//
//##

//** Default label background color
$label-default-bg:            $gray-medium !default;
//** Primary label background color
$label-primary-bg:            $brand-primary !default;
//** Success label background color
$label-success-bg:            $brand-success !default;
//** Info label background color
$label-info-bg:               $brand-info !default;
//** Warning label background color
$label-warning-bg:            $brand-warning !default;
//** Danger label background color
$label-danger-bg:             $brand-danger !default;

//** Default label text color
$label-color:                 $wb-white !default;
//** Default text color of a linked label
$label-link-hover-color:      $wb-white !default;


//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding:         15px !default;

//** Padding applied to the modal title
$modal-title-padding:         15px !default;
//** Modal title line-height
$modal-title-line-height:     $line-height-base !default;

//** Background color of modal content area
$modal-content-bg:                             $wb-white !default;
//** Modal content border color
$modal-content-border-color:                   rgba($wb-black, 0.2) !default;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color:          $gray-light !default;

//** Modal backdrop background color
$modal-backdrop-bg:           $wb-black !default;
//** Modal backdrop opacity
$modal-backdrop-opacity:      0.5 !default;
//** Modal header border color
$modal-header-border-color:   #e5e5e5 !default;
//** Modal footer border color
$modal-footer-border-color:   $modal-header-border-color !default;

$modal-lg:                    900px !default;
$modal-md:                    600px !default;
$modal-sm:                    300px !default;


//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:               15px !default;
$alert-border-radius:         $border-radius-base !default;
$alert-link-font-weight:      $wb-font-weight-bold !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-text:           $state-danger-text !default;
$alert-danger-border:         $state-danger-border !default;


//== Progress bars
//
//##

//** Background color of the whole progress component
$progress-bg:                 #f5f5f5 !default;
//** Progress bar text color
$progress-bar-color:          $wb-white !default;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius:      $border-radius-base !default;

//** Default progress bar color
$progress-bar-bg:             $brand-primary !default;
//** Success progress bar color
$progress-bar-success-bg:     $brand-success !default;
//** Warning progress bar color
$progress-bar-warning-bg:     $brand-warning !default;
//** Danger progress bar color
$progress-bar-danger-bg:      $brand-danger !default;
//** Info progress bar color
$progress-bar-info-bg:        $brand-info !default;


//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg:                 $wb-white !default;
//** `.list-group-item` border color
$list-group-border:             #ddd !default;
//** List group border radius
$list-group-border-radius:      $border-radius-base !default;

//** Background color of single list items on hover
$list-group-hover-bg:           #f5f5f5 !default;
//** Text color of active list items
$list-group-active-color:       $component-active-color !default;
//** Background color of active list items
$list-group-active-bg:          $component-active-bg !default;
//** Border color of active list elements
$list-group-active-border:      $list-group-active-bg !default;
//** Text color for content within active list items
$list-group-active-text-color:  lighten($list-group-active-bg, 40%) !default;

//** Text color of disabled list items
$list-group-disabled-color:      $gray-medium !default;
//** Background color of disabled list items
$list-group-disabled-bg:         $gray-lightest !default;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color:         $gray !default;
$list-group-link-hover-color:   $list-group-link-color !default;
$list-group-link-heading-color: $gray-dark !default;


//== Panels
//
//##

$panel-bg:                    transparent !default;
$panel-body-padding:          0 !default;
$panel-heading-padding:       0 !default;
$panel-footer-padding:        $panel-heading-padding !default;
$panel-border-radius:         $border-radius-base !default;
$wb-panel-box-shadow:         none !default;

//** Border color for elements within panels
$panel-inner-border:          #ddd !default;
$panel-footer-bg:             #f5f5f5 !default;

$panel-default-text:          $gray-dark !default;
$panel-default-border:        transparent !default;
$panel-default-heading-bg:    transparent !default;

$panel-primary-text:          $wb-white !default;
$panel-primary-border:        $brand-primary !default;
$panel-primary-heading-bg:    $brand-primary !default;

$panel-success-text:          $state-success-text !default;
$panel-success-border:        $state-success-border !default;
$panel-success-heading-bg:    $state-success-bg !default;

$panel-info-text:             $state-info-text !default;
$panel-info-border:           $state-info-border !default;
$panel-info-heading-bg:       $state-info-bg !default;

$panel-warning-text:          $state-warning-text !default;
$panel-warning-border:        $state-warning-border !default;
$panel-warning-heading-bg:    $state-warning-bg !default;

$panel-danger-text:           $state-danger-text !default;
$panel-danger-border:         $state-danger-border !default;
$panel-danger-heading-bg:     $state-danger-bg !default;

$wb-panel-title-font-family:  $headings-font-family !default;
$wb-panel-title-font-size:    $font-size-h4 !default;
$wb-panel-title-font-weight:  $headings-font-weight !default;
$wb-panel-title-line-height:  $headings-line-height !default;
$wb-panel-heading-legend-margin: 0 0 $line-height-computed 0 !default;

//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding:           4px !default;
//** Thumbnail background color
$thumbnail-bg:                $body-bg !default;
//** Thumbnail border color
$thumbnail-border:            #ddd !default;
//** Thumbnail border radius
$thumbnail-border-radius:     $border-radius-base !default;

//** Custom text color for thumbnail captions
$thumbnail-caption-color:     $text-color !default;
//** Padding around the thumbnail caption
$thumbnail-caption-padding:   9px !default;


//== Wells
//
//##

$well-bg:                     #f5f5f5 !default;
$well-border:                 darken($well-bg, 7%) !default;
$wb-well-padding:             19px !default;
$wb-well-shadow:              inset 0 1px 1px rgba(0, 0, 0, 0.05) !default;
$wb-well-border-radius:       $border-radius-base !default;
$wb-well-border:              1px solid $well-border !default;

//== Badges
//
//##

$badge-color:                 $wb-white !default;
//** Linked badge text color on hover
$badge-link-hover-color:      $wb-white !default;
$badge-bg:                    $gray-medium !default;

//** Badge text color in active nav link
$badge-active-color:          $link-color !default;
//** Badge background color in active nav link
$badge-active-bg:             $wb-white !default;

$badge-font-weight:           $wb-font-weight-bold !default;
$badge-line-height:           1 !default;
$badge-border-radius:         10px !default;


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical:   8px !default;
$breadcrumb-padding-horizontal: 15px !default;
//** Breadcrumb background color
$breadcrumb-bg:                 #f5f5f5 !default;
//** Breadcrumb text color
$breadcrumb-color:              #ccc !default;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       $gray-medium !default;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator:          '/' !default;


//== Carousel
//
//##

$carousel-text-shadow:                        0 1px 2px rgba($wb-black, 0.6) !default;

$carousel-control-color:                      $wb-white !default;
$carousel-control-width:                      15% !default;
$carousel-control-opacity:                    0.5 !default;
$carousel-control-font-size:                  20px !default;

$carousel-indicator-active-bg:                $wb-white !default;
$carousel-indicator-border-color:             $wb-white !default;

$carousel-caption-color:                      $wb-white !default;


//== Close
//
//##

$close-font-weight:           $wb-font-weight-bold !default;
$close-color:                 $wb-black !default;
$close-text-shadow:           0 1px 0 $wb-white !default;


//== Code
//
//##

$code-color:                  #c7254e !default;
$code-bg:                     #f9f2f4 !default;

$kbd-color:                   $wb-white !default;
$kbd-bg:                      $gray-dark !default;

$pre-bg:                      #f5f5f5 !default;
$pre-color:                   $gray-dark !default;
$pre-border-color:            #ccc !default;
$pre-scrollable-max-height:   340px !default;


//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;
//** Text muted color
$text-muted:                  $gray-medium !default;
//** Abbreviations and acronyms border color
$abbr-border-color:           $gray-medium !default;
//** Headings small color
$headings-small-color:        $gray-medium !default;
//** Blockquote small color
$blockquote-small-color:      $gray-medium !default;
//** Blockquote font size
$blockquote-font-size:        ($font-size-base * 1.25) !default;
//** Blockquote border color
$blockquote-border-color:     $gray-lightest !default;
//** Page header border color
$page-header-border-color:    $gray-lightest !default;
//** Width of horizontal description list titles
$dl-horizontal-offset:        $component-offset-horizontal !default;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint:    $grid-float-breakpoint !default;
//** Horizontal line color.
$hr-border:                   $gray-lightest !default;


//==  Custom components
//
//##

//== Webform icons
$wb-icon-refresh:                                     '\e8ba' !default;
$wb-icon-question:                                    '\e903' !default;
$wb-icon-chevron-down:                                '\e842' !default;
$wb-icon-chevron-up:                                  '\e845' !default;
$wb-icon-list:                                        '\e886' !default;
$wb-icon-search:                                      '\e8bd' !default;
$wb-icon-remove:                                      '\e8f6' !default;
$wb-icon-menu-left:                                   '\e843' !default;
$wb-icon-menu-right:                                  '\e844' !default;
$wb-icon-cog:                                         '\e8c3' !default;
$wb-icon-download:                                    '\e864' !default;
$wb-icon-export:                                      '\e863' !default;
$wb-icon-filter:                                      '\e875' !default;
$wb-icon-import:                                      '\e8e3' !default;
$wb-icon-ok:                                          '\e83f' !default;
$wb-icon-pencil:                                      '\e866' !default;
$wb-icon-plus:                                        '\e8b1' !default;
$wb-icon-trash:                                       '\e8da' !default;
$wb-icon-upload:                                      '\e8e1' !default;
$wb-icon-log-in:                                      '\e885' !default;
$wb-form-help-block-file-info-icon:                   '\e87f' !default;

$wb-form-error-label-text-color:                      $state-danger-text !default;
$wb-form-error-border-color:                          $state-danger-text !default;
$wb-form-error-background-color:                      $state-danger-bg !default;

//==  Webform progressbar

$wb-webform-progressbar-width:                        90% !default;
$wb-webform-progressbar-margin:                       40px auto !default;
$wb-webform-progressbar-outer-item-text-justify:      space-between !default;
$wb-webform-progressbar-baseline-color:               $gray-lighter !default;
$wb-webform-progressbar-baseline-height:              1px !default;
$wb-webform-progressbar-page-background:              $wb-white !default;
$wb-webform-progressbar-page-color:                   $text-color !default;
$wb-webform-progressbar-page-size:                    40px !default;
$wb-webform-progressbar-page-border:                  1px solid $gray-light !default;
$wb-webform-progressbar-page-border-radius:           $wb-webform-progressbar-page-size !default;
$wb-webform-progressbar-page-font-size:               16px !default;
$wb-webform-progressbar-page-item-padding:            0 !default;
$wb-webform-progressbar-page-item-font-weight:        $wb-font-weight-normal !default;
$wb-webform-progressbar-page-item-color:              inherit !default;
$wb-webform-progressbar-page-item-current-background-color: transparent !default;
$wb-webform-progressbar-page-item-completed-background: transparent !default;
$wb-webform-progressbar-page-item-current-color:      inherit !default;
$wb-webform-progressbar-page-sibling-item-margin:     0 !default;
$wb-webform-progressbar-page-item-number-margin:      0 auto !default;
$wb-webform-progressbar-page-completed-background:    $brand-success !default;
$wb-webform-progressbar-page-completed-color:         $wb-white !default;
$wb-webform-progressbar-page-completed-border:        1px solid $wb-brand-success-dark-200 !default;
$wb-webform-progressbar-page-completed-icon:          '\e83f' !default;
$wb-webform-progressbar-page-completed-icon-size:     $wb-icon-size-default !default;
$wb-webform-progressbar-page-current-background:      $brand-primary !default;
$wb-webform-progressbar-page-current-color:           $wb-white !default;
$wb-webform-progressbar-page-current-border:          1px solid $wb-brand-primary-dark-200 !default;
$wb-webform-progressbar-page-label-color:             $wb-webform-progressbar-page-color !default;
$wb-webform-progressbar-page-label-height:            20px !default;
$wb-webform-progressbar-page-label-margin:            5px 0 15px !default;

//== Webform timepicker

$wb-webform-time-item-spacing:                        10px !default;

//== Webform datepicker

$wb-webform-datepicker-item-spacing:                  10px !default;
$wb-webform-datepicker-calendar-icon-font-size:       $wb-icon-size-default !default;
$wb-webform-datepicker-calendar-icon-color:           $brand-primary !default;

//== Webform table grid

$wb-webform-grid-table-bg:                            $wb-white !default;

//== jQuery datepicker

$wb-ui-datepicker-border:                             0 !default;
$wb-ui-datepicker-border-radius:                      $border-radius-large !default;
$wb-ui-datepicker-background:                         $wb-white !default;
$wb-ui-datepicker-color:                              $text-color !default;
$wb-ui-datepicker-box-shadow:                         0 2px 4px rgba($wb-black, 0.17) !default;
$wb-ui-datepicker-padding:                            0.2em !default;
$wb-ui-datepicker-font-family:                        $font-family-base !default;
$wb-ui-datepicker-z-index:                            10 !important !default;
$wb-ui-datepicker-nav-border:                         none !default;
$wb-ui-datepicker-nav-border-radius:                  $border-radius-base !default;
$wb-ui-datepicker-nav-background:                     none !default;
$wb-ui-datepicker-nav-color:                          $gray-light !default;
$wb-ui-datepicker-nav-font-size:                      18px !default;
$wb-ui-datepicker-nav-height:                         46px !default;
$wb-ui-datepicker-nav-width:                          $wb-ui-datepicker-nav-height/2 !default;
$wb-ui-datepicker-nav-spacing-x:                      2px !default;
$wb-ui-datepicker-nav-spacing-y:                      2px !default;
$wb-ui-datepicker-nav-next-icon:                      '\e844' !default;
$wb-ui-datepicker-nav-prev-icon:                      '\e843' !default;
$wb-ui-datepicker-nav-hover-border:                   $wb-ui-datepicker-nav-border !default;
$wb-ui-datepicker-nav-hover-background:               $wb-ui-datepicker-nav-background !default;
$wb-ui-datepicker-nav-hover-color:                    $wb-gray-800 !default;
$wb-ui-datepicker-header-border:                      none !default;
$wb-ui-datepicker-header-border-radius:               $border-radius-large !default;
$wb-ui-datepicker-header-background:                  none !default;
$wb-ui-datepicker-header-color:                       $wb-ui-datepicker-color !default;
$wb-ui-datepicker-header-font-size:                   $font-size-large !default;
$wb-ui-datepicker-header-font-weight:                 $wb-font-weight-bold !default;
$wb-ui-datepicker-header-margin:                      0 !default;
$wb-ui-datepicker-header-padding:                     0 !default;
$wb-ui-datepicker-header-height:                      $wb-ui-datepicker-nav-height + 2 * $wb-ui-datepicker-nav-spacing-y !default;
$wb-ui-datepicker-week-border-x:                      0 !default;
$wb-ui-datepicker-week-border-y:                      2px solid #dfe4e8 !default;
$wb-ui-datepicker-week-background:                    none !default;
$wb-ui-datepicker-week-color:                         $gray-light !default;
$wb-ui-datepicker-week-font-size:                     $font-size-small !default;
$wb-ui-datepicker-week-font-weight:                   $wb-font-weight-normal !default;
$wb-ui-datepicker-week-text-align:                    center !default;
$wb-ui-datepicker-week-padding:                       1px !default;
$wb-ui-datepicker-week-size:                          32px !default;
$wb-ui-datepicker-calendar-body-background:           inherit !default;
$wb-ui-datepicker-day-border-x:                       1px solid transparent !default;
$wb-ui-datepicker-day-border-y:                       $wb-ui-datepicker-day-border-x !default;
$wb-ui-datepicker-day-border-radius:                  $border-radius-base !default;
$wb-ui-datepicker-day-background:                     none !default;
$wb-ui-datepicker-day-color:                          $wb-ui-datepicker-color !default;
$wb-ui-datepicker-day-font-size:                      $font-size-base !default;
$wb-ui-datepicker-day-font-weight:                    $wb-font-weight-normal !default;
$wb-ui-datepicker-day-text-align:                     center !default;
$wb-ui-datepicker-day-padding:                        1px !default;
$wb-ui-datepicker-day-size:                           36px !default;
$wb-ui-datepicker-day-line-height:                    $wb-ui-datepicker-day-size !default;
$wb-ui-datepicker-day-spacing:                        0 !default;
$wb-ui-datepicker-day-active-border-x:                $wb-ui-datepicker-day-border-x !default;
$wb-ui-datepicker-day-active-border-y:                $wb-ui-datepicker-day-active-border-x !default;
$wb-ui-datepicker-day-active-background:              $brand-primary !default;
$wb-ui-datepicker-day-active-color:                   $wb-white !default;
$wb-ui-datepicker-day-active-font-weight:             $wb-ui-datepicker-day-font-weight !default;
$wb-ui-datepicker-day-highlight-border-x:             $wb-ui-datepicker-day-border-x !default;
$wb-ui-datepicker-day-highlight-border-y:             $wb-ui-datepicker-day-highlight-border-x !default;
$wb-ui-datepicker-day-highlight-background:           $wb-ui-datepicker-day-background !default;
$wb-ui-datepicker-day-highlight-color:                $wb-ui-datepicker-day-color !default;
$wb-ui-datepicker-day-highlight-font-weight:          $wb-font-weight-bold !default;
$wb-ui-datepicker-day-hover-border-x:                 1px solid $wb-ui-datepicker-day-active-background !default;
$wb-ui-datepicker-day-hover-border-y:                 $wb-ui-datepicker-day-hover-border-x !default;
$wb-ui-datepicker-day-hover-background:               $wb-ui-datepicker-day-background !default;
$wb-ui-datepicker-day-hover-color:                    $wb-ui-datepicker-day-color !default;
$wb-ui-datepicker-day-hover-font-weight:              $wb-ui-datepicker-day-font-weight !default;
$wb-ui-datepicker-day-disabled-opacity:               0.35 !default;
$wb-ui-datepicker-day-disabled-background:            $wb-ui-datepicker-day-background !default;
$wb-ui-datepicker-day-disabled-color:                 $wb-ui-datepicker-day-color !default;
$wb-ui-daterangepicker-main-padding-bottom:           10px !default;
$wb-ui-daterangepicker-day-size:                      32px !default;
$wb-ui-daterangepicker-day-line-height:               $wb-ui-daterangepicker-day-size !default;
$wb-ui-daterangepicker-clearfix-padding:              15px 25px !default;
$wb-ui-daterangepicker-menu-item-padding:             5px 20px !default;
$wb-ui-daterangepicker-button-margin-left:            10px !default;
$wb-ui-daterangepicker-ui-menu-margin-mobile:         30px 0 15px 0 !default;
$wb-ui-daterangepicker-margin-mobile:                 0 20px !default;

// == Webform question grid

$wb-grid-question-full-width:                         true !default;

// == Webform layout box

$wb-layout-box-field-spacing-x:                       $grid-gutter-width / 2 !default;

// == Webform select2 dropdown

$wb-select2-input-active-box-shadow:                  none !default;
$wb-select2-input-padding:                            0 0 0 $wb-input-padding-x !default;
$wb-select2-input-height:                             $input-height-base !default;
$wb-select2-input-background:                         $input-bg !default;
$wb-select2-input-border-radius:                      $input-border-radius !default;
$wb-select2-input-border-color:                       $input-border !default;
$wb-select2-input-selected-line-height:               $wb-input-line-height !default;
$wb-select2-input-selected-padding:                   $wb-input-padding-y 0 !default;
$wb-select2-input-selected-color:                     $input-color !default;
$wb-select2-input-right-caret-icon-background-color:  transparent !default;
$wb-select2-input-right-caret-icon-spacing-right:     $wb-form-select-dropdown-icon-spacing-right !default;
$wb-select2-input-right-caret-icon-padding-x:         2px !default;
$wb-select2-input-right-caret-icon-width:             $wb-form-select-dropdown-icon-background-size !default;
$wb-select2-input-right-caret-icon-height:            4px !default;
$wb-select2-input-right-caret-icon-color:             $input-color !default;
$wb-select2-input-close-icon-padding-x:               2px !default;
$wb-select2-input-close-icon:                         '\e8f6' !default;
$wb-select2-input-close-icon-size:                    10px !default;
$wb-select2-input-close-icon-color:                    $brand-primary !default;

$wb-select2-dropdown-border-color:                    $input-border !default;
$wb-select2-dropdown-border:                          1px solid $wb-select2-dropdown-border-color !default;
$wb-select2-dropdown-border-radius:                   $input-border-radius !default;
$wb-select2-dropdown-background-color:                $wb-white !default;
$wb-select2-dropdown-box-shadow:                      0 6px 12px 0 rgba($wb-black, 0.17) !default;
$wb-select2-dropdown-box-shadow-above:                0 -6px 12px 0 rgba($wb-black, 0.17) !default;
$wb-select2-dropdown-top-margin:                      4px !default;
$wb-select2-dropdown-bottom-margin:                   4px !default;
$wb-select2-dropdown-results-padding:                 5px 0 !default;
$wb-select2-dropdown-results-margin:                  0 !default;
$wb-select2-dropdown-result-highlighted-color:        inherit !default;
$wb-select2-dropdown-result-highlighted-background-color: $gray-lightest !default;
$wb-select2-dropdown-result-label-padding:            0 20px !default;
$wb-select2-dropdown-result-label-line-height:        30px !default;
$wb-select2-dropdown-result-label-font-size:          inherit !default;

// == Webform select 2 multi select

$wb-select2-multiselect-close-left-offset:             0 !default;
$wb-select2-multiselect-close-icon-size:               22px !default;
$wb-select2-multiselect-close-icon-color:              #858585 !default;

$wb-select2-multiselect-border-radius:                 $input-border-radius !default;
$wb-select2-multiselect-border-color:                  $input-border !default;
$wb-select2-multiselect-option-spacing:                4px !default;
$wb-select2-multiselect-option-font-size:              $font-size-base !default;
$wb-select2-multiselect-option-line-height:            18px/$wb-select2-multiselect-option-font-size !default;
$wb-select2-multiselect-option-border-radius:          $border-radius-small !default;
$wb-select2-multiselect-option-border:                 1px solid #aaa !default;
$wb-select2-multiselect-option-padding-x:              5px !default;
$wb-select2-multiselect-option-padding-y:              2px !default;
$wb-select2-multiselect-option-padding:                $wb-select2-multiselect-option-padding-y $wb-select2-multiselect-option-padding-x $wb-select2-multiselect-option-padding-y $wb-select2-multiselect-close-icon-size !default;
$wb-select2-multiselect-option-background:             linear-gradient(180deg, #f4f4f4 0%, #c8c8c8 100%) !default;
$wb-select2-multiselect-option-font-family:            $font-family-base !default;
$wb-select2-multiselect-option-min-width:              100px !default;

// == Webform select 2 searched input

$wb-select2-search-box-input-margin:                   4px !default;
$wb-select2-search-box-input-icon-size:                14px !default;
$wb-select2-search-box-input-icon-right-shift:         5px !default;

// == Webform chosen

$wb-chosen-input-height:                              $wb-select2-input-height - 2 !default;
$wb-chosen-input-line-height:                         $wb-chosen-input-height !default;
$wb-chosen-input-padding:                             $wb-select2-input-padding !default;
$wb-chosen-search-field-padding-x:                    $wb-input-padding-x !default;

$wb-chosen-dropdown-margin:                           $wb-select2-dropdown-top-margin 0 $wb-select2-dropdown-bottom-margin - 1px !default;
$wb-chosen-dropdown-width:                            calc(100% + 2px) !default;
$wb-chosen-dropdown-min-width:                        100px !default;
$wb-chosen-dropdown-results-margin:                   $wb-select2-dropdown-results-margin !default;
$wb-chosen-dropdown-results-padding:                  $wb-select2-dropdown-results-padding !default;
$wb-chosen-dropdown-result-highlighted-color:         $text-color !default;
$wb-chosen-dropdown-result-highlighted-background-color: $wb-select2-dropdown-result-highlighted-background-color !default;

// == Webform autocomplete box for token input
$wb-autocomplete-search-icon-font-size:                12px !default;
$wb-autocomplete-search-icon-code:                     '\e8bd' !default;
$wb-autocomplete-input-border-radius:                  $input-border-radius !default;
$wb-autocomplete-input-border-color:                   $input-border !default;
$wb-autocomplete-input-height:                         $input-height-base !default;
$wb-autocomplete-input-font-size:                      $wb-input-font-size !default;
$wb-autocomplete-input-font-weight:                    $wb-font-weight-normal !default;
$wb-autocomplete-input-inset-margin:                   if($wb-input-padding-y < 4px, $wb-input-padding-y, 4px) !default; // Max of 4px or the input padding-y
$wb-autocomplete-input-padding-x:                      $wb-input-padding-x !default;
$wb-autocomplete-input-padding-y:                      $wb-input-padding-y - $wb-autocomplete-input-inset-margin !default;
$wb-autocomplete-input-token-background-color:         $wb-white !default;
$wb-autocomplete-input-token-text-color:               $input-color !default;
$wb-autocomplete-input-token-delete-icon-color:        inherit !default;
$wb-autocomplete-input-selected-token-background-color: $brand-primary !default;
$wb-autocomplete-input-selected-token-color:           $wb-white !default;

$wb-autocomplete-input-static-token-background-color:  $wb-autocomplete-input-selected-token-background-color !default;
$wb-autocomplete-input-static-token-color:             $wb-autocomplete-input-selected-token-color !default;

$wb-autocomplete-dropdown-font-family:                 $font-family-base !default;
$wb-autocomplete-dropdown-padding:                     5px 0 !default;
$wb-autocomplete-dropdown-z-index:                     2 !default;
$wb-autocomplete-dropdown-search-text-color:           $gray-medium !default;
$wb-autocomplete-dropdown-search-text-padding:         6px 20px !default;
$wb-fems-autocomplete-dropdown-search-font-weight:     $wb-font-weight-bold !default;
$wb-autocomplete-dropdown-item-padding:                6px 20px !default;
$wb-autocomplete-dropdown-item-font-size:              14px !default;
$wb-autocomplete-dropdown-item-line-height:            20px !default;
$wb-autocomplete-dropdown-item-stripped-bg-color:      #f5f5f5 !default;
$wb-autocomplete-dropdown-item-selected-bg-color:      $gray-lightest !default;
$wb-autocomplete-dropdown-item-selected-color:         $text-color !default;

// == Webform checkboxes and radio button

$wb-multi-select-options-color-active:                 #0072ff !default;
$wb-multi-select-options-color-hover:                  #4f4f4f !default;
$wb-multi-select-options-color:                        #757575 !default;

$wb-checkbox-background-color:                         $wb-white !default;
$wb-checkbox-border-color:                             $wb-multi-select-options-color !default;
$wb-checkbox-border-radius:                            3px !default;
$wb-checkbox-border-width:                             1px !default;
$wb-checkbox-border-color-hover:                       $wb-multi-select-options-color-hover !default;
$wb-checkbox-active-color:                             $wb-multi-select-options-color-active !default;
$wb-checkbox-active-checkmark-color:                   $wb-white !default;
$wb-checkbox-active-checkmark-border:                  2px !default;
$wb-checkbox-active-checkmark-left-offset:             2px !default;
$wb-checkbox-active-checkmark-width:                   9px !default;
$wb-checkbox-active-checkmark-height:                  5px !default;
$wb-checkbox-container-dimensions:                     13px !default;

$wb-radio-item-dimesions:                              13px !default;
$wb-radio-background-color:                            $wb-white !default;
$wb-radio-border-color:                                $wb-multi-select-options-color !default;
$wb-radio-border-width:                                1px !default;
$wb-radio-border-color-active:                         $wb-multi-select-options-color-active !default;
$wb-radio-dot-color-active:                            $wb-multi-select-options-color-active !default;
$wb-radio-dot-dimensions:                              7px !default;
$wb-radio-dot-left-offset:                             3px !default;

// == Civicrm Webform specific
$wb-civicrm-billing-card-icon-width: 48px !default;
$wb-civicrm-billing-card-icon-height: 35px !default;
$wb-civicrm-billing-card-icon-right-margin: 10px !default;
$wb-civicrm-billing-card-cvv-image-font-size: 30px !default;
$wb-civicrm-billing-card-cvv-image-right-spacing: 0 !default;
$wb-civicrm-billing-card-cvv-image-left-spacing: 10px !default;
$wb-civicrm-billing-card-cvv-right-padding: $wb-civicrm-billing-card-cvv-image-right-spacing + $wb-civicrm-billing-card-cvv-image-left-spacing + $wb-civicrm-billing-card-cvv-image-font-size !default;
$wb-civicrm-name-prefix-width: 150px !default;
$wb-civicrm-description-text-color: $gray-medium !default;
$wb-civicrm-description-text-size: $font-size-small !default;
$wb-accordion-header-icon-color: inherit !default;
$wb-accordion-body-border: 1px solid $gray-light !default;
$wb-accordion-body-border-radius: $border-radius-base !default;
$wb-accordion-body-padding: $padding-base-vertical $padding-base-horizontal !default;
$wb-accordion-body-background-color: inherit !default;
