/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

%chosen-single {
  background: transparent;
  border: 0;
  box-shadow: none;
  height: $wb-chosen-input-height;
  line-height: $wb-chosen-input-line-height;
  padding: 0;
}

.chosen-container {
  padding: $wb-chosen-input-padding;

  &,
  &.chosen-container-active.chosen-with-drop {
    .chosen-single {
      @extend %chosen-single;

      div {
        display: none;
      }

      span {
        margin-right: 0;
      }
    }
  }

  .chosen-drop {
    @extend %select2-drop;

    margin: $wb-chosen-dropdown-margin;
    min-width: $wb-chosen-dropdown-min-width;
    width: $wb-chosen-dropdown-width;
  }

  .chosen-results {
    margin: $wb-chosen-dropdown-results-margin;
    padding: $wb-chosen-dropdown-results-padding;

    li {
      @extend %select2-result-label;

      &.highlighted {
        background-color: $wb-chosen-dropdown-result-highlighted-background-color;
        background-image: none;
        color: $wb-chosen-dropdown-result-highlighted-color;
      }
    }
  }
}

.chosen-container-multi {
  padding: 0;

  .chosen-choices {
    @extend %chosen-single;

    li.search-field {
      /* stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type */
      input[type='text'] {
        padding-left: $wb-chosen-search-field-padding-x;
      }
      /* stylelint-enable selector-max-compound-selectors, selector-no-qualifying-type */
    }

    li.search-choice {
      @extend %select2-search-choice;

      /* stylelint-disable selector-max-compound-selectors */
      .search-choice-close {
        @extend %select2-search-choice-close;
      }
      /* stylelint-enable selector-max-compound-selectors */
    }
  }
}
