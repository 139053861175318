/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Core bootstrap tables styles overrides.
 */

.table {
  margin-bottom: 0;
}

.pagination {
  margin-bottom: 0;
  margin-top: $ssp-pagination-top-spacing;
}

.table-bordered {
  td {
    background: $ssp-brand-white;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;

    .btn {
      font-size: $ssp-table-button-font-size;
      font-weight: $ssp-table-button-font-weight;
      line-height: $ssp-table-button-line-height;
      padding: $ssp-table-button-padding;
    }
  }

  th {
    border: 0 !important;
  }

  td:first-child,
  th:first-child {
    padding-left: $ssp-table-mobile-prefix-space;
  }

  td:last-child,
  th:last-child {
    padding-right: $ssp-table-mobile-prefix-space;
  }

  @include for-tablet-landscape-up() {
    td:first-child,
    th:first-child {
      padding-left: $ssp-table-desktop-prefix-space;
    }

    td:last-child,
    th:last-child {
      padding-right: $ssp-table-desktop-prefix-space;
    }
  }
}
