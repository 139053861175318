/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

label {
  @include wb-label();
}

.form-control {
  box-shadow: $wb-form-control-box-shadow;
  font-size: $wb-input-font-size;
  line-height: $wb-input-line-height;
  padding: $wb-input-padding-y $wb-input-padding-x;
  transition: $wb-form-control-transition;

  &[readonly],
  &[disabled] {
    border-color: $wb-input-border-disabled;
  }

  &::-webkit-file-upload-button {
    background: $wb-file-upload-button-background-color;
    border: $wb-file-upload-button-border;
    color: $wb-file-upload-button-text-color;
    font-weight: $wb-file-upload-button-font-weight;
    padding: $wb-file-upload-button-padding;
    text-transform: $wb-file-upload-button-text-casing;
  }
}

.form-inline {
  .control-label {
    margin: $wb-form-control-inline-margin;
  }
}

.form-select {
  appearance: unset;
  background: $wb-form-select-dropdown-icon-background;
  background-size: $wb-form-select-dropdown-icon-background-size;
}

.form-required {
  color: $wb-form-control-required-color;
}

.help-block {
  color: $wb-form-help-text-color;

  > ul {
    padding: $wb-form-help-text-popup-padding;
  }

  > a {
    .icon {
      // Hide any existing icon
      display: none;
    }

    &::before {
      @include icon($wb-form-help-block-file-info-icon);
    }
  }
}

.form-actions {
  display: flex;
  flex-wrap: wrap;

  .form-submit {
    border-radius: $wb-form-submit-border-radius;
    padding: $wb-form-submit-padding;
  }
}

.has-error {
  @include form-control-validation($wb-form-error-label-text-color, $wb-form-error-border-color, $wb-form-error-background-color);
}
