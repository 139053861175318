/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.glyphicon {
  &-refresh {
    &::before {
      @include icon($wb-icon-refresh);
    }
  }

  &-question-sign {
    &::before {
      @include icon($wb-icon-question);
    }
  }

  &-list {
    &::before {
      @include icon($wb-icon-list);
    }
  }

  &-chevron-down {
    &::before {
      @include icon($wb-icon-chevron-down);
    }
  }

  &-chevron-up {
    &::before {
      @include icon($wb-icon-chevron-up);
    }
  }

  &-search {
    &::before {
      @include icon($wb-icon-search);
    }
  }

  &-remove {
    &::before {
      @include icon($wb-icon-remove);
    }
  }

  &-menu-left {
    &::before {
      @include icon($wb-icon-menu-left);
    }
  }

  &-menu-right {
    &::before {
      @include icon($wb-icon-menu-right);
    }
  }

  &-cog {
    &::before {
      @include icon($wb-icon-cog);
    }
  }

  &-download {
    &::before {
      @include icon($wb-icon-download);
    }
  }

  &-export {
    &::before {
      @include icon($wb-icon-export);
    }
  }

  &-filter {
    &::before {
      @include icon($wb-icon-filter);
    }
  }

  &-import {
    &::before {
      @include icon($wb-icon-import);
    }
  }

  &-ok {
    &::before {
      @include icon($wb-icon-ok);
    }
  }

  &-pencil {
    &::before {
      @include icon($wb-icon-pencil);
    }
  }

  &-plus {
    &::before {
      @include icon($wb-icon-plus);
    }
  }

  &-trash {
    &::before {
      @include icon($wb-icon-trash);
    }
  }

  &-upload {
    &::before {
      @include icon($wb-icon-upload);
    }
  }

  &-log-in {
    &::before {
      @include icon($wb-icon-log-in);
    }
  }
}
