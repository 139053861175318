/**
 * @file List of custom SASS variables
 *
 * @todo: Remove these variables when upgarded to SSP Bootstrap 1.4
 */

$rse-ssp-button-sm-font-size: 14px !default;
$rse-ssp-header-mobile-btn-max-width: 120px;
$rse-ssp-header-mobile-btn-user-icon: '\f2bd';
$rse-ssp-header-mobile-btn-user-icon-size: 22px;
$rse-ssp-header-mobile-btn-caret-margin: 0 0 0 10px;
