/**
 * @file
 *
 * This file contains core SSP bootstrap
 * componentds css styling overrides.
 *
 * @todo: Future plans -
 * Audit  the changes to filter out the styling
 * and incorporate basic color and spacing
 * changes inside core SSP bootstrap theme
 * so that these can be directly configured
 * through css variable for other sub themes.
 */

.ssp-contact-card__post-description {
  color: $ssp-brand-secondary-text-color;
}

.ssp-header {
  // Todo: Remove this code block when ssp is updated to 1.4 as they are already
  // part of ssp_bootstrap#79 PR
  @include for-phone-only() {
    .container-fluid {
      display: flex;
    }

    .ssp-logo {
      flex-grow: 1;

      img {
        height: auto;
        max-height: $ssp-header-text-line-height;
        max-width: 100%;
      }
    }

    .view-ssp-user-login-form,
    .view-ssp-user-register-page {
      .field-content {
        font-size: 0;
      }
    }

    .btn {
      font-size: $rse-ssp-button-sm-font-size;
      max-width: $rse-ssp-header-mobile-btn-max-width;
      overflow: hidden;
    }

    /* stylelint-disable selector-max-id */
    #block-menu-menu-ssp-user-menu {
      .btn {
        font-size: 0;

        /* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
        &::before {
          font-size: $rse-ssp-header-mobile-btn-user-icon-size;
          vertical-align: top;

          @include fa-icon($rse-ssp-header-mobile-btn-user-icon);
        }

        .caret,
        .fa {
          margin: $rse-ssp-header-mobile-btn-caret-margin;
          vertical-align: middle;
        }
        /* stylelint-enable max-nesting-depth, selector-max-compound-selectors */
      }
    }
    /* stylelint-enable selector-max-id */
  }
  // Todo: end.
}

// Todo: Remove this code block when ssp is updated to 1.4 as they are already
// part of ssp_bootstrap#79 PR
.ssp-logo {
  line-height: $ssp-header-text-line-height;
}
// Todo: end

.ssp-footer__logo {
  img {
    height: auto;
    width: 100%;
  }
}

.ssp-contact-card__view {
  // -5px to counter the top margin of the title
  margin-top: -5px;
}


.ssp-details-page-section-label-with-icon {
  >i {
    color: $ssp-brand-secondary-text-color;
  }
}
// Override no results behaviour images
.ssp-no-results {
  .view-ssp-events & {
    &::before {
      background-image: url('../images/no-results/events.svg');
    }
  }

  .view-ssp-committee-members &,
  .view-ssp-members-directory & {
    &::before {
      background-image: url('../images/no-results/members.svg');
    }
  }

  .view-ssp-committees-listing & {
    &::before {
      background-image: url('../images/no-results/committees.svg');
    }
  }

  .view-ssp-committee-meetings & {
    &::before {
      background-image: url('../images/no-results/meetings.svg');
    }
  }

  .view-ssp-committee-documents & {
    &::before {
      background-image: url('../images/no-results/documents.svg');
    }
  }

  .view-ssp-invoices & {
    &::before {
      background-image: url('../images/no-results/invoices.svg');
    }
  }

  .view-ssp-news & {
    &::before {
      background-image: url('../images/no-results/news.svg');
    }
  }

  .view-ssp-award-forms &,
  .view-ssp-awards-applications &,
  .view-ssp-awards-submitted & {
    &::before {
      background-image: url('../images/no-results/awards.svg');
    }
  }

  &.ssp-no-results--with-query {
    &::before {
      background-image: url('../images/no-results/with-query.svg');
    }
  }
}

.ssp-fixed-sidebar {
  white-space: nowrap;

  .ssp-sidebar-banner {
    white-space: normal;
  }
}
