/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.ssp-awards-review-application-status {
  .modal-body {
    align-items: center;
    display: flex;

    &::before {
      background: url('../images/document-check.svg') center center no-repeat;
      background-size: cover;
      content: '';
      display: block;
      flex-shrink: 0;
      height: $ssp-modal-review-body-image-height;
      margin-right: $ssp-modal-review-body-image-right-spacing;
      width: $ssp-modal-review-body-image-width;
    }
  }
}

// Small civicrm overrides for awards review warning page.
.CRM_CiviAwards_Form_AwardReview {
  .crm-form-block {
    background-color: transparent;

    .alert:only-child {
      margin-bottom: 0;
    }
  }

  .crm-frozen-field {
    @extend .form-control; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */

    height: auto;
  }
}
