/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Set negative margins to the object to eliminate well padding.
 */

@mixin unwell($type: 'left-right', $padding: -1, $border-radius: -1) {
  @if $padding == -1 {
    $padding: $ssp-bootstrap-well-padding;
  }

  @if $border-radius == -1 {
    $border-radius: $ssp-border-radius-default;
  }

  @if $type == 'top' {
    margin-top: -$padding;
  }

  @if $type == 'left-right' {
    margin-left: -$padding;
    margin-right: -$padding;
  }

  @if $type == 'top-left-right' {
    border-radius: $border-radius $border-radius 0 0;
    margin-top: -$padding;
    margin-left: -$padding;
    margin-right: -$padding;
  }

  @if $type == 'top-left-bottom' {
    border-radius: $border-radius 0 0 $border-radius;
    margin-top: -$padding;
    margin-left: -$padding;
    margin-bottom: -$padding;
  }
}
