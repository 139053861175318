/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 * Contains styles for Profile Image (civicrm) field.
 */

.civicrm-enabled {
  .civicrm-file-icon {
    img {
      max-height: $ssp-civicrm-image-field-image-height;
      max-width: $ssp-civicrm-image-field-image-width;
    }
  }

  &.form-managed-file {
    margin: $ssp-civicrm-image-field-margin;

    .civicrm-remove-file {
      background: $ssp-civicrm-image-field-button-remove-background;
      border: $ssp-civicrm-image-field-button-remove-border;
      color: $ssp-civicrm-image-field-button-remove-color;
      font-size: $ssp-civicrm-image-field-button-remove-font-size;
      margin: $ssp-civicrm-image-field-button-remove-margin;

      @include hover {
        background: $ssp-civicrm-image-field-button-remove-hover-background;
        border: $ssp-civicrm-image-field-button-remove-hover-border;
        color: $ssp-civicrm-image-field-button-remove-hover-color;
        outline: none;
      }
    }
  }
}
