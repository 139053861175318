/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.view-ssp-public-members-directory {
  .views-widget-filter-circle {
    .form-item {
      clear: none;
    }

    .select2-container {
      width: auto !important;
    }
  }

  .view-filters {
    clear: both;
    width: 100%;
  }

  .gm-ui-hover-effect {
    // Greater than .ssp-card__link z-index which is 2.
    z-index: 3;
  }

  .views-widget-filter-country_id {
    display: none;
  }

  @include for-tablet-portrait-up() {
    .views-widget-filter-circle {
      width: $ssp-proximity-filter-container-width !important;
    }

    .civicrm-proximity-location,
    .form-item-circle-country {
      width: $ssp-proximity-filter-inputs-width;
    }

    .form-item-circle-distance {
      margin-right: 0;
      width: $ssp-proximity-filter-distance-width;
    }

    .views-bootstrap-grid-plugin-style {
      float: left;
      overflow: scroll;
      width: 50%;

      .row {
        margin-right: 0;
      }
    }

    .attachment {
      .view-ssp-public-members-directory {
        float: right;
        width: 50%;
      }
    }
  }

  @include for-phone-only() {
    .views-widget-filter-circle {
      width: auto !important;
    }

    .views-bootstrap-grid-plugin-style {
      width: 100%;
    }

    .attachment {
      .view-ssp-public-members-directory {
        width: 100%;
      }
    }
  }
}
