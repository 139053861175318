/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.block-ssp-core-cpd {
  i {
    color: $gray-medium;
    margin-right: $ssp-button-icon-margin;
  }

  .ssp-cpd-current-hours {
    font-size: $ssp-cpd-header-current-hours-font-size;
    font-weight: $ssp-font-weight-bold;
    padding-left: $ssp-cpd-header-current-hours-padding-left;
  }

  .ssp-cpd-hours-divider {
    padding: $ssp-cpd-header-hours-divider-padding;
  }

  .ssp-cpd-total-hours {
    font-size: $ssp-cpd-header-total-hours-font-size;
    font-weight: $ssp-font-weight-bold;
  }
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.view-ssp-cpd-activities {
  .well {
    border: 0;
    margin-bottom: $ssp-cpd-well-margin-bottom;
  }

  i {
    margin-right: $ssp-button-icon-margin;
  }

  /* stylelint-disable-next-line selector-max-compound-selectors */
  .views-exposed-form .views-exposed-widgets .form-item {
    max-width: none;
  }

  .form-item-range {
    display: flex;
    flex-wrap: wrap;
    margin-left: $ssp-cpd-radio-wrapper-margin-left;
    position: relative;

    .radio {
      margin-bottom: $ssp-cpd-radio-margin-bottom;
      margin-right: $ssp-cpd-radio-margin-right;
      margin-top: 0;

      &:last-child {
        margin-right: 0;
      }

      @include for-phone-only() {
        width: 100%;
      }
    }

    button {
      border: 0;
      height: 0;
      margin: 0;
      opacity: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      right: 0;
      top: 40px;
      width: 0;
    }
  }

  .radio {
    cursor: pointer;
    position: relative;

    &.active::after {
      background: $ssp-brand-primary;
      border-radius: $ssp-cpd-radio-border-radius;
      content: '';
      display: block;
      height: $ssp-cpd-radio-after-active-width;
      left: $ssp-cpd-radio-after-left;
      position: absolute;
      top: $ssp-cpd-radio-after-top;
      width: $ssp-cpd-radio-after-active-width;
    }

    &::before {
      border: $ssp-cpd-radio-before-border;
      border-radius: $ssp-cpd-radio-border-radius;
      content: ' ';
      display: inline-block;
      height: $ssp-cpd-radio-before-width;
      margin-right: $ssp-cpd-radio-before-margin-right;
      position: relative;
      top: $ssp-cpd-radio-before-top;
      width: $ssp-cpd-radio-before-width;
    }
  }

  .panel {
    border: 0;
  }

  .panel-group {
    /* stylelint-disable-next-line selector-max-compound-selectors */
    .panel+.panel {
      margin-top: $ssp-cpd-panel-margin-top;
    }

    /* stylelint-disable-next-line selector-max-compound-selectors */
    .panel-heading + .panel-collapse {
      /* stylelint-disable-next-line selector-max-compound-selectors */
      > .panel-body {
        border-color: $ssp-cpd-panel-border-color;
      }
    }
  }

  .panel-heading {
    background-color: $ssp-brand-white;
    padding: $accordion-heading-padding;

    a {
      align-items: center;
      display: flex;

      &:not(.collapsed) {
        color: $ssp-cpd-color;

        /* stylelint-disable-next-line max-nesting-depth */
        &::before {
          background-image: escape-svg($accordion-button-active-icon);
          transform: $accordion-icon-transform;
        }
      }

      // Accordion icon
      &::before {
        background-image: escape-svg($accordion-button-icon);
        background-repeat: no-repeat;
        background-size: $accordion-icon-width;
        content: '';
        flex-shrink: 0;
        height: $accordion-icon-width;
        margin-right: $accordion-icon-margin;
        width: $accordion-icon-width;
        @include transition($accordion-icon-transition);
      }
    }

    .badge {
      font-size: $ssp-cpd-panel-badge-font-size;
      margin-left: auto;

      &.zero-hours {
        background-color: $ssp-cpd-panel-zero-hours-badge-background-color;
      }
    }
  }

  .ssp-edit-cpd-activity-modal-button {
    border-color: $ssp-cpd-color;
    border-radius: $ssp-border-radius-default;
    color: $ssp-cpd-color;
    font-weight: $ssp-font-weight-medium;
    padding-left: $ssp-cpd-edit-button-padding-x;
    padding-right: $ssp-cpd-edit-button-padding-x;
  }

  .ssp-delete-cpd-activity-modal-button {
    background: $ssp-cpd-delete-button-background-color;
    border-color: $ssp-cpd-delete-button-background-color;
    border-radius: $ssp-border-radius-default;
    font-weight: $ssp-font-weight-medium;
    padding-left: $ssp-cpd-delete-button-padding-x;
    padding-right: $ssp-cpd-delete-button-padding-x;
  }
}

.cpd__headline {
  &--basic-details {
    margin-bottom: $ssp-cpd-header-h3-margin-bottom !important;
  }

  &--total-hours {
    margin-bottom: $ssp-cpd-header-h3-margin-bottom/2 !important;
  }

  &--filters {
    margin-bottom: $ssp-cpd-header-h3-margin-bottom !important;
    margin-left: $ssp-cpd-radio-wrapper-margin-left * -1 !important;
    width: 100%;
  }
}

.cpd-header {
  &__ul {
    display: flex;
    flex-direction: column;
    row-gap: $ssp-cpd-header-ul-row-gap;
  }

  &__li {
    display: flex;
  }

  &__label {
    flex-basis: $ssp-cpd-header-label-flex-basis-mobile;
    flex-shrink: 0;
    font-weight: $ssp-font-weight-medium;

    @include for-tablet-portrait-up() {
      flex-basis: $ssp-cpd-header-label-flex-basis;
      padding-left: $ssp-cpd-header-label-padding-left;
    }
  }
}

.cpd-actions {
  padding-bottom: $ssp-cpd-actions-padding-bottom;
  padding-top: $ssp-cpd-actions-padding-top;

  &__button {
    &::before {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      content: '';
      display: inline-block;
      height: $ssp-cpd-button-icon-height;
      margin-right: $ssp-button-icon-margin;
      width: $ssp-cpd-button-icon-width;
    }

    &--add {
      background-color: $ssp-cpd-color;

      &::before {
        background-image: escape-svg($ssp-add-icon);
      }

      @include for-tablet-portrait-up() {
        padding-left: $ssp-cpd-add-button-padding-x;
        padding-right: $ssp-cpd-add-button-padding-x;
      }
    }

    &--export {
      border-color: $ssp-cpd-color;
      color: $ssp-cpd-color;

      &::before {
        background-image: escape-svg($ssp-export-icon);
      }

      @include for-tablet-portrait-up() {
        padding-left: $ssp-cpd-export-button-padding-x;
        padding-right: $ssp-cpd-export-button-padding-x;
      }
    }

    @include for-phone-only() {
      display: block;
      margin: $ssp-cpd-actions-button-margin-mobile;
      width: $ssp-cpd-actions-button-width-mobile;
    }
  }
}

.ssp-no-results__locked,
.ssp-no-results__under-review {
  color: $ssp-cpd-no-results-header-color;
  display: none;

  @include for-phone-only() {
    font-size: $ssp-headline-h6-font-size;
  }
}

.ssp-cpd-activities--locked {
  .ssp-no-results__header {
    display: none;
  }

  .ssp-no-results__locked {
    display: block;
  }
}

.ssp-cpd-activities--under-review {
  .ssp-no-results__header {
    display: none;
  }

  .ssp-no-results__under-review {
    display: block;
  }
}

.ssp-cpd-list {
  margin-bottom: 0;
  padding: 0;
}

.ssp-cpd-list__item {
  border-bottom: $ssp-cpd-list-item-border;
  border-color: $ssp-cpd-panel-border-color;
  list-style: none;
  padding-bottom: $ssp-cpd-list-item-padding-x;
  padding-top: $ssp-cpd-list-item-padding-x;
  position: relative;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }

  @include for-tablet-portrait-up() {
    margin-left: $ssp-cpd-list-item-margin-left;
  }
}

.ssp-cpd-list__item-meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.ssp-cpd-list__item-actions {
  margin: $ssp-cpd-list-item-actions-margin;

  > div {
    display: inline-block;
    padding: $ssp-cpd-list-item-actions-padding;
  }

  @include for-tablet-landscape-up() {
    margin-bottom: unset;
    margin-top: unset;
  }
}

.ssp-cpd-list__item-datetime {
  color: $ssp-cpd-list-item-datetime-color;
  flex-grow: 1;
}

.ssp-cpd-list__item-content {
  padding: $ssp-cpd-list-item-content-padding;

  > h3 {
    color: $ssp-cpd-list-item-heading-color;
    font-size: $ssp-cpd-list-item-heading-font-size;
    margin: $ssp-cpd-list-item-heading-padding;
  }

  p {
    color: $ssp-cpd-list-item-heading-color;
    line-height: $ssp-cpd-list-item-p-line-height;
  }

  @include for-tablet-landscape-up() {
    width: $ssp-cpd-list-item-content-width;
  }
}

.ssp-cpd-list__item-title {
  margin-bottom: $ssp-cpd-h3-margin-bottom !important;
}

.ssp-cpd-list__show-more {
  color: $ssp-cpd-color;
  display: none;
  font-size: $ssp-cpd-list-item-evidence-font-size;
  font-weight: $ssp-font-weight-medium;
  line-height: $ssp-cpd-list-item-p-line-height;
  text-decoration-line: underline;
}

.ssp-cpd-list__item-evidence {
  margin-top: $ssp-cpd-panel-margin-top;

  a {
    line-height: $ssp-cpd-list-item-p-line-height;
  }
}

/* stylelint-disable selector-max-id */
#ui-datepicker-div {
  z-index: 1050 !important;
}

.vertical-align {
  align-items: center;
  display: flex;
}

/* stylelint-disable selector-no-qualifying-type */
#ssp-core-cpd-form-cpd-activity {
  .panel-body > div[id*='ajax-wrapper'] {
    flex-basis: calc(#{$ssp-cpd-panel-body-width});
  }

  .form-type-managed-file {

    padding-right: $ssp-horizontal-spacing;
    @include for-tablet-landscape-up() {
      width: $ssp-cpd-managed-file-width;
    }
  }

  div[id*='ajax-wrapper'] {
    flex-direction: column;
  }
}

/* stylelint-disable-next-line selector-max-compound-selectors */
#ssp-cpd-activity .webform-client-form > div > * {
  padding: $ssp-cpd-modal-content-padding;
}

@include for-tablet-landscape-down() {
  /* stylelint-disable-next-line selector-max-compound-selectors */
  #ssp-cpd-activity .webform-client-form .panel-body > .form-item {
    flex-basis: calc(100%);
  }
}

#ssp-cpd-activity .form-type-textarea {
  flex-basis: calc(100%) !important;
}
