/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * CiviCRM social network block styles.
 */

/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable selector-max-id, max-nesting-depth, selector-max-compound-selectors, selector-no-qualifying-type*/

#crm-container {
  &.crm-public {
    .crm-socialnetwork {
      margin-top: $line-height-computed;
      position: relative;

      h3 {
        padding: 0;
      }

      &::before {
        background: url('../images/help-grow.svg') center center no-repeat;
        background-size: cover;
        content: '';
        display: block;
        padding-top: $ssp-donate-image-height/$ssp-donate-image-width * 100%;
        width: 100%;

        @include for-tablet-portrait-up() {
          bottom: $ssp-bootstrap-well-padding;
          left: $ssp-bootstrap-well-padding;
          padding-top: $ssp-donate-image-height;
          position: absolute;
          width: $ssp-donate-image-width;
        }

        @extend .ssp-donate__image;
      }

      &,
      .description {
        color: inherit;
        font-size: inherit;
      }

      .description {
        margin-bottom: $form-group-margin-bottom;
      }

      br {
        display: none;

        + p {
          padding-top: $form-group-margin-bottom;
        }
      }

      p {
        clear: both;
      }

      .crm-fb-tweet-buttons {
        display: flex;

        .label {
          float: none;
          font-size: inherit !important;
          overflow: hidden;
          padding: 0 !important;
          width: $civicrm-help-spread-block-social-icons-width !important;

          iframe[src*='facebook.com'] {
            height: $civicrm-help-spread-block-social-fb-icon-height !important;
          }
        }

        @include for-tablet-portrait-up() {
          flex-direction: column;
          position: absolute;
          right: $ssp-bootstrap-well-padding;
          top: 50%;
          transform: translate(0, -50%);
          width: auto;
        }
      }

      *:last-child {
        span {
          display: block;
          margin-bottom: $ssp-civicrm-multi-options-label-bottom-margin;
        }
      }

      @include for-tablet-portrait-up() {
        padding-left: $ssp-donate-image-width + $ssp-bootstrap-well-padding + $ssp-donate-image-right-spacing;
        padding-right: $civicrm-help-spread-block-social-icons-width + $ssp-bootstrap-well-padding * 2;
      }

      @extend .well;
    }
  }
}
