/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Library integration related styles - used on dashboard
 */

.ssp-wiley-block,
.ssp-oxford-university-press-block {
  padding-top: 0 !important;
}

.ssp-wiley__button,
.ssp-oxford-university-press__button {
  padding-top: $ssp-bootstrap-well-padding * 2;

  @include for-phone-only() {
    padding: $ssp-bootstrap-well-padding * 2 0;
  }
}

.ssp-wiley__image,
.ssp-oxford-university-press__image {
  max-width: $ssp-donate-image-width;

  @include for-tablet-desktop-up() {
    margin-top: -#{$ssp-bootstrap-well-padding * 3};
  }
}
