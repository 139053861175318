/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Dashboard page styles.
 */

.page-ssp-dashboard {
  .region-content {
    .block {
      /* stylelint-disable selector-max-compound-selectors */
      .block-title {
        margin-top: 0;
      }
      /* stylelint-enable */
    }
  }

  .sub-link {
    padding-top: $ssp-button-padding-y;
  }
}
