/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Contains colors, spacing and other theme related sass variables.
 */

// Colors
$ssp-brand-primary:          $brand-primary !default;
$ssp-brand-secondary:        #f9fbff !default;
$ssp-brand-gray-base:        #000 !default;
$ssp-brand-gray-darker:      $gray-darker !default;
$ssp-brand-gray-dark:        $gray-dark !default;
$ssp-brand-gray:             $gray !default;
$ssp-brand-gray-light:       $gray-light !default;
$ssp-brand-gray-lighter:     $gray-lighter !default;
$ssp-brand-milky-white:      $gray-lightest !default;
$ssp-brand-white:            #fff !default;

$ssp-brand-success:          $brand-success !default;
$ssp-brand-text-success:     $state-success-text !default;
$ssp-brand-status-success:   $brand-success !default;
$ssp-brand-info:             $brand-info !default;
$ssp-brand-warning:          $brand-warning !default;
$ssp-brand-danger:           $brand-danger !default;
$ssp-warning-bg:             $state-warning-bg !default;

$ssp-body-background-color: $body-bg !default;
$ssp-brand-link-color: $ssp-brand-primary !default;
$ssp-icon-background-color: $ssp-brand-primary !default;
$ssp-icon-success-background-color: $ssp-brand-success !default;

// Border radius
$ssp-border-radius-default: $border-radius-base !default;

// Font sizes
$ssp-headline-h1-font-size: $font-size-h1 !default;
$ssp-headline-h2-font-size: $font-size-h2 !default;
$ssp-headline-h3-font-size: $font-size-h3 !default;
$ssp-headline-h4-font-size: $font-size-h4 !default;
$ssp-headline-h5-font-size: $font-size-h5 !default;
$ssp-headline-h6-font-size: $font-size-h6 !default;
$ssp-headline-line-height:  $headings-line-height !default;

// Line-heights
$ssp-headline-h1-line-height: $ssp-headline-line-height !default;
$ssp-headline-h2-line-height: $ssp-headline-line-height !default;
$ssp-headline-h3-line-height: $ssp-headline-line-height !default;
$ssp-headline-h4-line-height: $ssp-headline-line-height !default;
$ssp-headline-h5-line-height: $ssp-headline-line-height !default;
$ssp-headline-h6-line-height: $ssp-headline-line-height !default;

// Font weights
$ssp-font-weight-normal:              400 !default;
$ssp-font-weight-medium:              500 !default;
$ssp-font-weight-bold:                700 !default;

// Buttons
$ssp-button-font-size:                inherit !default;
$ssp-button-line-height:              inherit !default;
$ssp-button-padding-y:                6px !default;
$ssp-button-padding-x:                10px !default;
$ssp-button-padding:                  $ssp-button-padding-y $ssp-button-padding-x !default;
$ssp-button-border-radius:            $ssp-border-radius-default !default;
$ssp-button-border-color-primary:     $ssp-brand-primary !default;
$ssp-button-text-color-primary:       $ssp-brand-white !default;
$ssp-button-background-color-primary: $ssp-brand-primary !default;
$ssp-button-weight:                   $btn-font-weight !default; // Changed from 700
$ssp-button-icon-margin:              10px !default;
$ssp-button-icon-margin-sm:           3px !default;
$ssp-button-disabled-opacity:         0.65 !default;
$ssp-button-sm-font-size:             14px !default;

$ssp-cancel-button-border-color:       #ccc !default;
$ssp-cancel-button-background-color:   #fff !default;
$ssp-cancel-button-color:              #333 !default;

// Media breakpoints
$ssp-breakpoint-sm: 768px !default;
$ssp-breakpoint-md: 992px !default;
$ssp-breakpoint-lg: 1200px !default;
$ssp-breakpoint-xl: 1800px !default;
$ssp-breakpoint-height-sm: 720px !default;

// Container widths
$ssp-container-width-portrait: 750px !default;
$ssp-container-width-landscape: 970px !default;
$ssp-container-width-desktop: 1170px !default;

// Margins and spacing
$ssp-vertical-spacing: 40px !default;
$ssp-horizontal-spacing: 15px !default;

// Header and sidebar variables
$ssp-header-text-line-height: 20px !default;
$ssp-header-vertical-padding: 10px !default;
$ssp-header-padding: $ssp-header-vertical-padding #{$ssp-horizontal-spacing} !default;
$ssp-header-border: 1px !default;
$ssp-header-component-vertical-spacing: 5px !default;
$ssp-header-menu-border-width: 1px !default;
$ssp-header-background-color: $ssp-brand-primary !default;
$ssp-header-border-color: $ssp-brand-primary !default;
$ssp-header-text-color: $ssp-brand-white !default;
$ssp-header-height: 30px !default;
$ssp-header-total-height: $ssp-header-vertical-padding * 2 + $ssp-header-border * 2 + $ssp-header-text-line-height + $ssp-header-component-vertical-spacing * 2 !default;
$ssp-header-logo-right-spacing: 10px !default;
$ssp-header-title-line-height: $ssp-headline-line-height !default;
$ssp-header-button-font-weight: $ssp-font-weight-normal !default;
$ssp-fixed-sidebar-width: 220px !default;
$ssp-fixed-sidebar-background-color: $ssp-brand-white !default;
$ssp-fixed-sidebar-box-shadow: 1px 0 2px 0 rgba($ssp-brand-gray-base, 0.08) !default;
$ssp-fixed-sidebar-logo-mobile-max-height: 50px !default;
$ssp-fixed-sidebar-logo-max-width: 180px !default;
$ssp-header-mobile-btn-max-width: 120px !default;
$ssp-header-mobile-btn-user-icon: '\f2bd' !default;
$ssp-header-mobile-btn-user-icon-size: 22px !default;
$ssp-header-mobile-btn-caret-margin: 0 0 0 10px !default;

// Navigation variables
$ssp-navbar-link-color: $ssp-brand-gray !default;
$ssp-navbar-link-padding: 15px !default;
$ssp-navbar-link-active-color: $ssp-brand-white !default;
$ssp-navbar-link-active-background-color: $ssp-brand-primary !default;
$ssp-navbar-link-active-font-weight: $ssp-font-weight-bold !default;
$ssp-navbar-link-hover-color: $ssp-brand-white !default;
$ssp-navbar-link-hover-background-color: $ssp-brand-primary !default;
$ssp-navbar-link-icon-spacing: 16px !default;
$ssp-navbar-link-separator-spacing: 10px !default;
$ssp-navbar-link-separator-border-color: $ssp-brand-gray-lighter !default;

// Breadcrumb variables
$ssp-breadcrumb-padding: 5px;

// Icon sizes
$ssp-icon-standard-size: 16px !default;
$ssp-icon-quot-width: 30px !default;
$ssp-icon-quot-padding: 25px !default;
$ssp-icon-quot-padding-mobile: 12px !default;

// Breadcrumb
$ssp-breadcrumb-margin: -30px 0 20px 0 !default;

// Footer
$ssp-footer-background-color: $ssp-brand-gray-lighter !default;
$ssp-footer-text-color: inherit !default;
$ssp-footer-vertical-padding: 25px !default;
$ssp-footer-padding: $ssp-footer-vertical-padding #{$ssp-horizontal-spacing} !default;
$ssp-footer-logo-padding: 25px !default;
$ssp-footer-logo-width: 37px !default;
$ssp-footer-logo-height: 37px !default;
$ssp-footer-address-bottom-spacing: 5px !default;

// Bootstrap Elements Overrides
$ssp-bootstrap-well-padding: $wb-well-padding !default;
$ssp-bootstrap-well-border-width: 1px !default;
$ssp-bootstrap-well-dashboard-block-bottom-spacing: 15px !default;
$ssp-bootstrap-well-headline-border: 0 !default;
$ssp-bootstrap-well-headline-line-height: $ssp-headline-line-height !default;
$ssp-bootstrap-well-headline-margin: 0 0 8px !default;
$ssp-bootstrap-well-title-line-height: $ssp-headline-line-height !default;
$ssp-bootstrap-well-title-margin: 0 0 5px !default;
$ssp-bootstrap-form-group-bottom-spacing: $form-group-margin-bottom !default;
$ssp-bootstrap-breadcrumb-leaf-color: $ssp-brand-gray-light !default;
$ssp-bootstrap-form-control-placeholder: $ssp-brand-gray-light !default;
$ssp-bootstrap-form-control-bottom-description-bottom-margin: 5px !default;
$ssp-bootstrap-quot-margin: 20px 0 !default;
$ssp-bootstrap-quot-padding: $ssp-bootstrap-well-padding $ssp-bootstrap-well-padding $ssp-bootstrap-well-padding ($ssp-icon-quot-width + 2 * $ssp-icon-quot-padding) !default;
$ssp-bootstrap-quot-background-position: $ssp-icon-quot-padding $ssp-icon-quot-padding !default;
$ssp-bootstrap-quot-min-height: $ssp-icon-quot-width + 2 * $ssp-icon-quot-padding !default;
$ssp-bootstrap-quot-padding-mobile: $ssp-bootstrap-well-padding $ssp-bootstrap-well-padding $ssp-bootstrap-well-padding ($ssp-icon-quot-width + 2 * $ssp-icon-quot-padding-mobile) !default;
$ssp-bootstrap-quot-background-position-mobile: $ssp-icon-quot-padding-mobile (1.5 * $ssp-icon-quot-padding-mobile) !default;

// Nav tabs
$ssp-navtabs-spacing-y: 20px !default;
$ssp-navtabs-margin-bottom: $ssp-navtabs-spacing-y !default;
$ssp-navtabs-margin-top: $ssp-navtabs-spacing-y !default;
$ssp-navtabs-mobile-font-size: 14px !default;
$ssp-navtabs-mobile-line-height: 1.125 !default;
$ssp-navtabs-mobile-padding: 10px !default;
$ssp-navtabs-desktop-font-size: 16px !default;
$ssp-navtabs-desktop-line-height: 1.125 !default;
$ssp-navtabs-desktop-padding: 10px 20px !default;
$ssp-navtabs-active-background-color: $ssp-brand-milky-white !default;
$ssp-navtabs-active-font-weight: $ssp-font-weight-bold;
$ssp-navpills-default-color: $ssp-brand-gray !default;

// Custom
$ssp-side-image-top-adjustment: -15px !default;
$ssp-side-image-right-adjustment: -5px !default;
$ssp-content-body-vertical-margin: 10px !default;
$ssp-separator-color: $ssp-brand-gray !default;
$ssp-separator-font-weight: $ssp-font-weight-bold !default;
$ssp-content-description-bottom-margin: 10px !default;
$ssp-content-description-color: $ssp-brand-gray !default;
$ssp-icon-outer-dimensions: 76px !default;
$ssp-icon-inner-dimensions: 28px !default;
$ssp-icon-small-outer-dimensions: 50px !default;
$ssp-icon-small-inner-dimensions: 24px !default;
$ssp-icon-radius: 100% !default;
$ssp-animation-duration: 0.1s !default;
$ssp-backdrop-tranparency: 0.4 !default;
$ssp-well-prefix-bottom-spacing: 10px !default;
$ssp-well-prefix-icon-size: 12px !default;
$ssp-form-box-header-bottom-spacing: 20px !default;
$ssp-view-card-bottom-margin: 10px !default;
$ssp-footer-content-spacing: 40px !default;
$ssp-pagination-top-spacing: 20px !default;
$ssp-page-header-margin: 20px 0 15px 0 !default;
$ssp-block-title-bottom-margin: $ssp-bootstrap-well-padding !default;
$ssp-text-large: 16px !default;

// Dashboard Donate block
$ssp-donate-image-width: 205px !default;
$ssp-donate-image-height: 148px !default;
$ssp-donate-image-right-spacing: 30px !default;

// My Memberships block
$ssp-memberships-renewal-info-year-size: 16px !default;
$ssp-memberships-renewal-info-spacing: 8px 15px !default;
$ssp-memberships-block-message-spacing: 15px !default;
$ssp-memberships-block-message-max-width: 650px !default;
$ssp-memberships-block-min-height: 225px !default;
$ssp-memberships-item-name-min-width: 350px !default;
$ssp-memberships-list-separator: 20px !default;
$ssp-memberships-list-block-padding: 18px 24px !default;
$ssp-memberships-list-item-font-size: 18px !default;
$ssp-memberships-list-item-border: 1px solid $gray-lighter;
$ssp-memberships-list-item-padding: 15px 0 !default;
$ssp-memberships-icon-padding-top: 8px !default;
$ssp-memberships-icon-margin-right: 15px !default;
$ssp-memberships-icon-font-size: 32px !default;
$ssp-memberships-payment-method-min-width: 300px !default;
$ssp-memberships-payment-method-to-options-description-ml: 30px !default;
$ssp-memberships-outstanding-invoices-description-mb: 30px !default;
$ssp-memberships-outstanding-invoices-total-amount-table-margin: 20px 0 !default;
$ssp-memberships-outstanding-invoices-total-amount-table-width: 345px !default;
$ssp-memberships-outstanding-invoices-total-amount-table-td-width: 120px !default;
$ssp-memberships-outstanding-invoices-total-amount-table-td-pl: 8px !default;

// Image placeholders
$ssp-user-image-placeholder-icon-color: $ssp-brand-milky-white !default;
$ssp-user-image-placeholder-icon-font-size-small: 48px !default;
$ssp-user-image-placeholder-icon-font-size-large: 128px !default;
$ssp-user-image-placeholder-background: $ssp-brand-gray-lighter !default;
$ssp-user-image-placeholder-icon: '\f2bd' !default;

// Full card
$ssp-full-card-subtitle-line-bottom-spacing: 10px !default;
$ssp-full-card-content-line-bottom-spacing: 5px !default;
$ssp-full-card-subtitle-text-color: $ssp-brand-gray-light !default;
$ssp-full-card-content-left-spacing: 10px !default;
$ssp-full-card-footer-top-spacing: 10px !default;
$ssp-full-card-footer-links-spacing: 10px !default;

// Contact Card
$ssp-contact-card-well-padding: $ssp-bootstrap-well-padding !default;
$ssp-contact-card-description-text-color: $ssp-brand-gray-light !default;
$ssp-contact-card-post-description-text-color: inherit !default;
$ssp-contact-card-title-font-size: $ssp-headline-h5-font-size !default;
$ssp-contact-card-title-font-weight: $ssp-font-weight-bold !default;
$ssp-contact-card-title-bottom-margin: 10px !default;
$ssp-contact-card-description-font-size: 12px !default;
$ssp-contact-card-description-bottom-margin: 5px !default;
$ssp-contact-card-post-description-font-size: 12px !default;
$ssp-contact-card-post-description-line-height: 1.5 !default;
$ssp-contact-card-image-width: 94px !default;
$ssp-contact-card-image-height: 94px !default;
$ssp-contact-card-button-icon-font-size: 0.9em !default;
$ssp-contact-card-button-icon-spacing: 2px !default;
$ssp-contact-card-no-image-background: $ssp-user-image-placeholder-background !default;
$ssp-contact-card-no-image-icon-color: $ssp-user-image-placeholder-icon-color !default;
$ssp-contact-card-no-image-icon-font-size: $ssp-user-image-placeholder-icon-font-size-small !default;
$ssp-contact-card-well-height: 118px !default;

// Applicants cards
$ssp-applicant-card-padding: $ssp-bootstrap-well-padding !default;
$ssp-applicant-card-footer-top-spacing: $ssp-applicant-card-padding !default;
$ssp-applicant-card-footer-padding-x: $ssp-applicant-card-padding !default;
$ssp-applicant-card-footer-padding-y: $ssp-applicant-card-padding !default;
$ssp-applicant-card-footer-label-padding-y: 5px !default;
$ssp-applicant-card-footer-label-line-height: 14px/12px !default;
$ssp-applicant-card-footer-border-color: $ssp-brand-gray-lighter !default;
$ssp-applicant-footer-action-top-adjustment: if($ssp-applicant-card-padding > $ssp-applicant-card-footer-padding-y, $ssp-applicant-card-padding - $ssp-applicant-card-footer-padding-y, 0);
$ssp-applicant-card-title-bottom-spacing: 30px !default;
$ssp-applicant-card-award-color: $ssp-brand-gray-light !default;
$ssp-applicant-card-status-font-size: 12px !default;
$ssp-applicant-card-status-color: inherit !default;
$ssp-applicant-card-status-status-font-weight: inherit !default;
$ssp-applicant-card-status-icon-font-size: $ssp-icon-standard-size !default;
$ssp-individiual-applicant-attachment-heading-margin: 0 0 5px 0 !default;
$ssp-individiual-applicant-bottom-spacing: 10px !default;
$ssp-individiual-applicant-attachment-field-lists-margin: 0 0 10px 0 !default;
$ssp-applicant-card-action-position-right: $ssp-applicant-card-padding !default;
$ssp-applicant-card-action-position-top: $ssp-applicant-card-padding !default;
$ssp-applicant-card-action-button-color: $ssp-button-background-color-primary !default;
$ssp-applicant-card-action-button-font-size: inherit !default;

$ssp-applicant-form-action-buttons-padding: $ssp-button-padding-y $ssp-button-padding-x !default;
$ssp-applicant-form-cancel-button-color: $ssp-cancel-button-color !default;
$ssp-applicant-form-cancel-button-background-color: $ssp-cancel-button-background-color !default;
$ssp-applicant-form-cancel-button-border-color: $ssp-cancel-button-border-color !default;

// No result behaviour
$ssp-no-results-top-margin: 80px !default;
$ssp-no-results-sm-top-margin: 30px !default;
$ssp-no-results-image-width: 260px !default;
$ssp-no-results-image-height: 175px !default;
$ssp-no-results-sm-image-height: 150px !default;
$ssp-no-results-header-margin: 30px 0 20px 0 !default;
$ssp-no-results-content-max-width: 380px !default;
$ssp-no-results-button-margin: 20px auto 0 auto !default;

// SSP labels
$ssp-label-default: $ssp-brand-gray-light !default;
$ssp-label-success: $ssp-brand-status-success !default;
$ssp-label-danger: $ssp-brand-danger !default;
$ssp-label-warning: $ssp-brand-warning !default;
$ssp-label-padding: 0.2em 0.6em 0.3em !default;
$ssp-label-radius: 0.25em !default;
$ssp-label-font-size: 12px !default;
$ssp-label-font-weight: $ssp-font-weight-normal !default;

// Table
$ssp-table-mobile-prefix-space: 15px !default;
$ssp-table-desktop-prefix-space: 50px !default;
$ssp-table-button-font-size: 12px !default;
$ssp-table-button-padding: 0 5px !default;
$ssp-table-button-line-height: 1.5 !default;
$ssp-table-button-font-weight: $ssp-font-weight-normal !default;
$ssp-table-paynow-width: 120px !default;
$ssp-table-form-action-button-spacing-y: 20px !default;

// My Organisations table
$ssp-table-organisations-field-id-width: 50px !default;
$ssp-table-organisations-field-phone-width: 135px !default;
$ssp-table-organisations-field-optin-width: 65px !default;

// My Organisations page
$ssp-my-organisations-linear-gradient-null: 0% !default;
$ssp-my-organisations-linear-gradient-full: 100% !default;
$ssp-my-organisations-date-padding-top: 24px !default;
$ssp-my-organisations-date-right: 8px !default;
$ssp-my-organisations-border-radius: 4px !default;
$ssp-my-organisations-date-left: 92px !default;
$ssp-my-organisations-date-padding-right: 3px !default;
$ssp-my-organisations-font-weight: 900 !default;
$ssp-my-organisations-gray-color: #e0e0e0 !default;
$ssp-my-organisations-white-color: #fefefe !default;

// Details page
$ssp-details-page-fields-bottom-margin: 20px !default;
$ssp-details-page-fields-right-labels-margin: 5px !default;
$ssp-details-page-fields-left-labels-margin: 5px !default;
$ssp-details-page-fields-icon-adjustment: 32px !default;
$ssp-details-page-fields-icon-x: 0 !default;
$ssp-details-page-fields-icon-y: 3px !default;
$ssp-details-page-header-margin: 20px 0 10px 0 !default;
$ssp-details-page-content-padding: 25px !default;
$ssp-details-page-awards-text-right-spacing: 15px !default;
$ssp-details-page-sub-heading-bottom-spacing: 20px !default;
$ssp-details-page-paragraph-bottom-spacing: 15px !default;
$ssp-details-page-action-bottom-spacing: 20px !default;
$ssp-details-page-no-image-background: $ssp-brand-gray-lighter !default;
$ssp-details-page-no-image-icon-color: $ssp-brand-milky-white !default;
$ssp-details-page-no-image-icon-font-size: $ssp-user-image-placeholder-icon-font-size-large !default;

// Proximity filter widget (on public members directory)
$ssp-proximity-filter-container-width: 525px !default;
$ssp-proximity-filter-inputs-width: 200px !default;
$ssp-proximity-filter-distance-width: 105px !default;

// Forms
$ssp-form-help-text-spacing: 5px !default;

// Password
$ssp-form-password-feedback-icon-width: 34px !default;
$ssp-form-password-feedback-icon-spacing-x: $ssp-bootstrap-well-padding !default;
$ssp-form-password-label-height: 14px !default;
$ssp-form-password-label-spacing-x: $ssp-horizontal-spacing !default;

// Date widget
$ssp-date-widget-width: 40px !default;
$ssp-date-widget-radius: 5px !default;
$ssp-date-widget-background-color: $ssp-brand-secondary !default;
$ssp-date-widget-month-background-color: $ssp-brand-primary !default;
$ssp-date-widget-month-text-color: $ssp-brand-white !default;
$ssp-date-widget-month-font-size: 12px !default;
$ssp-date-widget-month-line-height: 18/12 !default;
$ssp-date-widget-day-text-color: $ssp-brand-primary !default;
$ssp-date-widget-day-font-size: 24px !default;
$ssp-date-widget-day-line-height: 34/24 !default;
$ssp-date-widget-day-font-weight: $ssp-font-weight-bold !default;

// Inline date picker (3 select boxes)
$ssp-inline-datepicker-item-width-month: 101px !default;
$ssp-inline-datepicker-item-width-day: 88px !default;
$ssp-inline-datepicker-item-width-year: 106px !default;
$ssp-inline-datepicker-item-spacing: 5px !default;

// Time picker (2 select boxes)
$ssp-inline-timepicker-item-width: 96px !default;
$ssp-inline-timepicker-item-spacing: 5px !default;

// Profile Image field (civicrm file) and related button
$ssp-civicrm-image-field-margin: 0 0 10px 0 !default;
$ssp-civicrm-image-field-image-width: 100px !default;
$ssp-civicrm-image-field-image-height: 100px !default;
$ssp-civicrm-image-field-button-remove-margin: 0 !default;
$ssp-civicrm-image-field-button-remove-font-size: inherit !default;
$ssp-civicrm-image-field-button-remove-color: $ssp-brand-link-color !default;
$ssp-civicrm-image-field-button-remove-background: none !default;
$ssp-civicrm-image-field-button-remove-border: 0 !default;
$ssp-civicrm-image-field-button-remove-hover-color: $ssp-brand-link-color !default;
$ssp-civicrm-image-field-button-remove-hover-background: $ssp-civicrm-image-field-button-remove-background !default;
$ssp-civicrm-image-field-button-remove-hover-border: $ssp-civicrm-image-field-button-remove-border !default;

// CiviCRM
$ssp-civicrm-multi-options-label-font-weight: $ssp-font-weight-normal !default;
$ssp-civicrm-multi-options-top-margin: 3px !default;
$ssp-civicrm-multi-options-label-bottom-margin: 5px !default;
$ssp-civicrm-dropdown-elements-max-width: 553px !default;
$ssp-accordion-header-padding: $padding-base-vertical $padding-base-horizontal !default;

// Filters
$ssp-filters-search-box-width: 250px !default;
$ssp-filters-input-buttons-width: 250px !default;
$ssp-long-filters-input-buttons-width: 270px !default;
$ssp-filters-fields-horizontal-spacing: 10px !default;
$ssp-filters-show-hide-bottom-spacing: 5px !default;
$ssp-filters-icon-horizontal-spacing: 10px !default;
$ssp-filters-icon-adjustment-spacing: 30px !default;
$ssp-filters-field-separator-spacing: 12px !default;
$ssp-filters-lateral-spacing: 25px !default;
$ssp-filters-date-field-width: 128px !default;

// Ajax spinner
$ssp-spinner-backdrop-blend-color: $ssp-brand-milky-white !default;
$ssp-spinner-backdrop-blend-ratio: 0.7 !default;
$ssp-spinner-backdrop-color: rgba($ssp-spinner-backdrop-blend-color, $ssp-spinner-backdrop-blend-ratio) !default;
$ssp-spinner-color: currentColor !default;
$ssp-spinner-border: 0.25em !default;
$ssp-spinner-height: 20px !default;
$ssp-spinner-width: 20px !default;
$ssp-spinner-top-margin: 40px !default;

// Panel variables
$ssp-panel-heading-margin: 0 !default;
$ssp-panel-heading-font-family: inherit !default;
$ssp-panel-heading-font-size: $ssp-headline-h3-font-size !default;
$ssp-panel-heading-line-height: $ssp-headline-line-height !default;
$ssp-panel-heading-font-weight: $ssp-font-weight-medium !default;
$ssp-internal-panel-heading-font-family: $ssp-panel-heading-font-family !default;
$ssp-internal-panel-heading-font-size: $ssp-headline-h4-font-size !default;
$ssp-internal-panel-heading-bottom-margin: 15px !default;
$ssp-webform-panel-margin: 5px 0 10px 0 !default;
// stylelint-disable-next-line scss/dollar-variable-colon-space-after
$ssp-webform-two-coloumn-layout-skip-selector:
  '.name-prefix',
  '.first-name',
  '.middle-name',
  '.last-name',
  '.email' !default;
$ssp-collapsible-panel-spacing: 5px !default;
$ssp-collapsible-panel-border-radius: $ssp-border-radius-default !default;
$ssp-collapsible-panel-border-color: $ssp-brand-gray-lighter !default;
$ssp-collapsible-panel-border: 1px solid $ssp-collapsible-panel-border-color !default;
$ssp-collapsible-panel-heading-padding: 10px $ssp-bootstrap-well-padding !default;

// Modal
$ssp-modal-md: $modal-md !default; // Changed from 410px;
$ssp-modal-dialog-box-offset-y-sm: 10px !default;
$ssp-modal-dialog-box-offset-y-md: 87px !default;
$ssp-modal-inner-padding: $modal-inner-padding !default; // Changed from 15px 25px
$ssp-modal-title-padding: $modal-title-padding !default;// Changed from 15px 25px
$ssp-modal-header-bg-color: $ssp-brand-white !default;
$ssp-modal-body-bg-color: $ssp-brand-white !default;
$ssp-modal-footer-bg-color: $ssp-brand-white !default;
$ssp-modal-close-button-positioning-right: 25px !default;
$ssp-modal-close-button-positioning-top: 20.5px !default;
$ssp-modal-close-button-opacity: 1 !default;
$ssp-modal-close-button-size: 16px !default;
$ssp-modal-close-button-icon-border-width: 1px !default;
$ssp-modal-close-button-icon-border-length: 20px !default;
$ssp-modal-close-button-icon-border-color: $ssp-brand-gray-base !default;
$ssp-modal-iframe-loader-height: 200px !default;
$ssp-modal-iframe-loader-font-size: 20px !default;

$ssp-modal-footer-close-button-min-width: auto !default;
$ssp-modal-footer-close-button-border-color: $ssp-cancel-button-border-color !default;
$ssp-modal-footer-close-button-color: $ssp-cancel-button-color !default;
$ssp-modal-footer-close-button-background-color: $ssp-cancel-button-background-color !default;
$ssp-modal-footer-button-padding: $ssp-button-padding-y $ssp-button-padding-x !default;

$ssp-modal-review-body-image-height: 60px !default;
$ssp-modal-review-body-image-width: 50px !default;
$ssp-modal-review-body-image-right-spacing: 20px !default;

// SSP my organisation modal
$ssp-modal-manage-member-form-content-height: 250px !default;

// Sidebar banner
$ssp-sidebar-banner-width: 445px !default;
$ssp-sidebar-banner-logo-background-color: rgba($ssp-fixed-sidebar-background-color, 0.3) !default;
$ssp-sidebar-banner-logo-padding: 20px 10px !default;
$ssp-sidebar-banner-logo-spacing-y: $ssp-header-vertical-padding !default;
$ssp-sidebar-banner-logo-spacing-x: $ssp-horizontal-spacing !default;
$ssp-sidebar-banner-logo-border-radius: $ssp-border-radius-default !default;
$ssp-sidebar-banner-text-padding: 0 40px !default;
$ssp-sidebar-banner-text-color: $ssp-brand-white !default;
$ssp-sidebar-banner-text-font-size: $ssp-headline-h2-font-size !default;
$ssp-sidebar-banner-text-line-height: $ssp-headline-line-height !default;
$ssp-sidebar-banner-tint-color: rgba($ssp-brand-gray-base, 0.75) !default;

// Civicrm variables
$civicrm-selectbox-right-spacing: 10px !default;
$civicrm-recurring-contribution-input-button-width: 40px !default;
$civicrm-crm-group-bottom-margin: 10px !default;
$civicrm-form-legend-bottom-margin: 10px !default;
$civicrm-contribution-organisation-fieldset-top-spacing: 20px !default;
$civicrm-inline-error-padding: 5px !default;
$civicrm-inline-error-font-size: 14px !default;
$civicrm-inline-error-font-weight: $ssp-font-weight-bold !default;
$civicrm-help-spread-block-social-icons-width: 63px !default;
$civicrm-help-spread-block-social-fb-icon-height: 20px !default;
$civicrm-form-table-elements-spacer: 20px !default;

// Civicrm award review form variables
$civicrm-awards-button-margin-spacing: 0 6px 0 0 !default;

// Paragraph section
$sc-paragraph-standard-gap-sm: 16px !default;
$sc-paragraph-standard-gap: $sc-paragraph-standard-gap-sm * 1.5 !default;
$sc-paragraph-standard-gap-md: 40px !default;
$sc-paragraph-standard-gap-lg: 70px !default;
$sc-paragraph-spacing-y-sm: 60px !default;
$sc-paragraph-spacing-y: $sc-paragraph-spacing-y-sm * 2 !default;
$sc-paragraph-font-size-sm: 14px !default;
$sc-paragraph-font-size: 16px !default;
$sc-paragraph-font-size-lg: 18px !default;
$sc-paragraph-line-height-sm: 26px !default;
$sc-paragraph-line-height: 28px !default;
$sc-paragraph-line-height-lg: 32px !default;
$sc-paragraph-title-font-size-sm: 28px !default;
$sc-paragraph-title-font-size: 42px !default;
$sc-paragraph-heading-font-size: 48px !default;
$sc-paragraph-title-line-height-sm: 42px !default;
$sc-paragraph-title-line-height: 58px !default;
$sc-paragraph-title-max-width: 700px !default;
$sc-paragraph-title-margin-sm: 0 auto  $sc-paragraph-standard-gap-sm !default;
$sc-paragraph-title-margin: 0 auto $sc-paragraph-standard-gap !default;
$sc-paragraph-sub-title-font-size: 22px !default;
$sc-paragraph-sub-title-line-height: 32px !default;
$sc-paragraph-sub-title-margin: 0 0 $sc-paragraph-standard-gap !default;
$sc-paragraph-sub-title-margin-sm: 0 0 $sc-paragraph-standard-gap-sm !default;
$sc-paragraph-description-max-width: 780px !default;
$sc-paragraph-description-font-size-sm: $sc-paragraph-font-size !default;
$sc-paragraph-description-font-size: $sc-paragraph-font-size-lg !default;
$sc-paragraph-description-line-height-sm: $sc-paragraph-line-height !default;
$sc-paragraph-description-line-height: $sc-paragraph-line-height-lg !default;
$sc-paragraph-inner-section-spacing-sm: $sc-paragraph-standard-gap-md * 0.75 !default;
$sc-paragraph-inner-section-spacing: $sc-paragraph-standard-gap-md !default;

$sc-paragraph-card-border-radius-sm: 8px !default;
$sc-paragraph-card-border-radius: $sc-paragraph-card-border-radius-sm * 2 !default;
$sc-paragraph-card-background-color: $ssp-brand-white !default;
$sc-paragraph-card-small-padding-sm: 10px $sc-paragraph-standard-gap-sm !default;
$sc-paragraph-card-small-padding: 10px $sc-paragraph-standard-gap !default;
$sc-paragraph-card-small-image-height-sm: 48px !default;
$sc-paragraph-card-small-image-height: $sc-paragraph-card-small-image-height-sm * 2 !default;
$sc-paragraph-card-big-padding: $sc-paragraph-standard-gap-md $sc-paragraph-standard-gap !default;
$sc-paragraph-card-form-padding: $sc-paragraph-standard-gap-md !default;
$sc-paragraph-card-box-shadow: 0 30px 40px rgba(212, 217, 232, 0.2) !default;

$sc-paragraph-horizontal-cards-icon-min-width: 64px !default;
$sc-paragraph-horizontal-cards-count: 3 !default;

$sc-paragraph-features-row-count-desktop: 8 !default;
$sc-paragraph-features-row-count-tablet: 4 !default;
$sc-paragraph-features-row-count-mobile: 2 !default;
$sc-paragraph-features-icon-min-width: 32px !default;

$sc-paragraph-image-text-body-max-width: 480px !default;
$sc-paragraph-image-text-wide-image-width: 40% !default;

$sc-paragraph-webform-max-width: 540px !default;

// Helper variables
$ssp-helper-mt-4: 20px !default;
$ssp-helper-btn-wide-padding: 10px 50px !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29'),
) !default;

// Accordion
$accordion-heading-padding: 20px 20px !default;
$accordion-icon-width: 24px !default;
$accordion-icon-margin: 10px !default;
$accordion-icon-color: currentColor !default;
$accordion-icon-active-color: $ssp-brand-primary !default;
$accordion-icon-transition: transform 0.2s ease-in-out !default;
$accordion-icon-transform: rotate(-180deg) !default;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$accordion-icon-color}' class='bi bi-plus-circle-fill' viewBox='0 0 16 16'><path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z'/></svg>") !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$accordion-icon-active-color}' class='bi bi-dash-circle-fill' viewBox='0 0 16 16'><path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z'/></svg>") !default;

// CPD activity variables
$ssp-cpd-no-results-margin-bottom: 25px !default;
$ssp-cpd-no-results-width: 165px !default;
$ssp-cpd-no-results-width-mobile: 130px !default;
$ssp-cpd-no-results-height: 145px !default;
$ssp-cpd-no-results-margin-left: 50px !default;
$ssp-cpd-no-results-margin-top: 35px !default;
$ssp-cpd-no-results-header-margin-left: -5px !default;
$ssp-cpd-no-results-header-color: $gray-light !default;
$ssp-cpd-header-h3-margin-bottom: 30px !default;
$ssp-cpd-header-ul-row-gap: 15px !default;
$ssp-cpd-header-label-flex-basis-mobile: 200px !default;
$ssp-cpd-header-label-flex-basis: 230px !default;
$ssp-cpd-header-label-padding-left: 25px !default;
$ssp-cpd-actions-padding-bottom: 30px !default;
$ssp-cpd-actions-padding-top: 15px !default;
$ssp-cpd-actions-button-margin-mobile: 5px auto !default;
$ssp-cpd-actions-button-width-mobile: 70% !default;
$ssp-cpd-header-current-hours-font-size: $font-size-h1 * 2 !default;
$ssp-cpd-header-current-hours-padding-left: 25px !default;
$ssp-cpd-header-hours-divider-padding: 0 15px !default;
$ssp-cpd-header-total-hours-font-size: $font-size-h1 !default;
$ssp-cpd-color: $ssp-brand-primary !default;
$ssp-cpd-well-margin-bottom: 10px !default;
$ssp-cpd-radio-wrapper-margin-left: 25px !default;
$ssp-cpd-radio-margin-bottom: 10px !default;
$ssp-cpd-radio-margin-right: 55px !default;
$ssp-cpd-h3-margin-bottom: 15px !default;
$ssp-cpd-month-radio-margin-left: 25px !default;
$ssp-cpd-radio-before-width: 14px !default;
$ssp-cpd-radio-before-margin-right: 5px !default;
$ssp-cpd-radio-before-border: 1px solid $ssp-cancel-button-border-color !default;
$ssp-cpd-radio-after-active-width: 10px !default;
$ssp-cpd-radio-after-left: 2px !default;
$ssp-cpd-radio-after-top: 5px !default;
$ssp-cpd-radio-before-top: 2px !default;
$ssp-cpd-radio-border-radius: 50% !default;
$ssp-cpd-panel-margin-top: 15px !default;
$ssp-cpd-panel-border-color: rgba($table-border-color, 0.5) !default;
$ssp-cpd-panel-badge-font-size: $font-size-base !default;
$ssp-cpd-panel-zero-hours-badge-background-color: $ssp-cancel-button-border-color !default;
$ssp-cpd-add-button-padding-x: 25px !default;
$ssp-cpd-button-icon-width: $font-size-base !default;
$ssp-cpd-button-icon-height: $font-size-base !default;
$ssp-cpd-export-button-padding-x: 65px !default;
$ssp-cpd-edit-button-padding-x: 30px !default;
$ssp-cpd-delete-button-background-color: $ssp-brand-danger !default;
$ssp-cpd-delete-button-padding-x: 20px !default;
$ssp-cpd-h5-font-size: $font-size-base !default;
$ssp-cpd-list-item-border: 1px solid !default;
$ssp-cpd-list-item-padding-x: 20px !default;
$ssp-cpd-list-item-margin-left: 25px !default;
$ssp-cpd-list-item-content-padding: 0 25px 0 0 !default;
$ssp-cpd-list-item-content-width: 640px !default;
$ssp-cpd-list-item-heading-padding: 0 0 10px !default;
$ssp-cpd-list-item-heading-font-size: $ssp-text-large !default;
$ssp-cpd-list-item-heading-color: $ssp-brand-gray-darker !default;
$ssp-cpd-list-item-p-line-height: $line-height-large !default;
$ssp-cpd-list-item-actions-margin: 20px -1*$padding-base-horizontal/2 !default;
$ssp-cpd-list-item-actions-padding: 0 $padding-base-horizontal/2 !default;
$ssp-cpd-list-item-datetime-color: $ssp-brand-gray-dark !default;
$ssp-cpd-list-item-evidence-font-size: $ssp-text-large !default;
$ssp-cpd-panel-body-width: 100% !default;
$ssp-cpd-managed-file-width: 50% !default;
$ssp-cpd-modal-content-padding: 15px 25px !default;
$ssp-add-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='14' fill='none'><g clip-path='url(#a)'><path fill='#{$ssp-brand-white}' d='M1.667 7a.73.73 0 0 0 .73.729H6.65a.122.122 0 0 1 .121.121v4.254a.73.73 0 1 0 1.458 0V7.85a.122.122 0 0 1 .122-.121h4.253a.73.73 0 0 0 0-1.459H8.351a.122.122 0 0 1-.122-.121V1.895a.73.73 0 0 0-1.458 0V6.15a.122.122 0 0 1-.121.121H2.396a.73.73 0 0 0-.729.73Z'/></g><defs><clipPath id='a'><path fill='none' d='M1.667 1.166h11.667v11.667H1.667z'/></clipPath></defs></svg>") !default;
$ssp-export-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' fill='none'><g clip-path='url(#a)'><path fill='#{$ssp-cpd-color}' d='M12.5 11v2a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-2a1 1 0 0 0-2 0v2a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-2a1 1 0 0 0-2 0ZM6.794.293l-4 4a1 1 0 1 0 1.414 1.414L6.5 3.415V10a1 1 0 1 0 2 0V3.415l2.293 2.294a1 1 0 1 0 1.414-1.415l-4-4A.997.997 0 0 0 6.794.293Z'/></g><defs><clipPath id='a'><path fill='#fff' d='M.5 0h14v16H.5z'/></clipPath></defs></svg>") !default;
