/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.webform-client-form {
  &,
  .panel-body {
    margin-left: -$wb-layout-box-field-spacing-x / 2;
    margin-right: -$wb-layout-box-field-spacing-x / 2;
  }

  > div,
  > div > .well,
  .panel-body {
    display: flex;
    flex-wrap: wrap;

    > * { /* stylelint-disable-line selector-max-compound-selectors */
      flex-basis: 100%;
    }
  }

  > div,
  .panel-body {
    > * {
      padding-left: $wb-layout-box-field-spacing-x / 2;
      padding-right: $wb-layout-box-field-spacing-x / 2;
    }
  }

  // File field have no '.form-item' class (no classes at all) somehow,
  // just an id like: edit-submitted-cool-file-ajax-wrapper.
  /* stylelint-disable selector-no-qualifying-type */
  div[id*='ajax-wrapper'] {
    display: flex;

    .form-item {

      @include for-tablet-portrait-up() {
        &.wb-field-half-span { /* stylelint-disable-line max-nesting-depth */
          flex-basis: calc(50% - #{$wb-layout-box-field-spacing-x});
        }
      }
      flex-basis: 100%;
    }
  }
  /* stylelint-enable */

  .form-item {

    @include for-tablet-portrait-up() {
      &.wb-field-half-span {
        flex-basis: calc(50% - #{$wb-layout-box-field-spacing-x});
      }
    }

  }

  .wb-fieldset-with-half-span-fields {
    > .panel-body {
      /* stylelint-disable selector-no-qualifying-type, selector-max-compound-selectors, max-nesting-depth */
      > .form-item,
      > div[id*='ajax-wrapper'] {
        @include for-tablet-portrait-up() {

          @include webform-full-width-fields {
            flex-basis: 100%;
          }
          flex-basis: calc(50% - #{$wb-layout-box-field-spacing-x});
          flex-grow: 1;
        }
      }
      /* stylelint-enable selector-no-qualifying-type, selector-max-compound-selectors, max-nesting-depth */
    }
  }

  .wb-component-civicrm-contact-fieldset {
    .name-prefix {
      flex-basis: $wb-civicrm-name-prefix-width;
      flex-grow: 1;
    }

    .first-name {
      flex-basis: calc(33.33333% - #{$wb-civicrm-name-prefix-width/3});
      flex-grow: 1;
    }

    .middle-name {
      flex-basis: calc(33.33333% - #{$wb-civicrm-name-prefix-width/3});
      flex-grow: 1;
    }

    .last-name {
      flex-basis: calc(33.33333% - #{$wb-civicrm-name-prefix-width/3});
      flex-grow: 1;
    }
  }
}
