/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */


/* stylelint-disable selector-max-id */
#ui-datepicker-div,
.comiseo-daterangepicker {
  background: $wb-ui-datepicker-background;
  border: $wb-ui-datepicker-border;
  border-radius: $wb-ui-datepicker-border-radius;
  box-shadow: $wb-ui-datepicker-box-shadow;
  font-family: $wb-ui-datepicker-font-family;
  padding: $wb-ui-datepicker-padding;
  width: auto;

  .ui-datepicker {
    &-header {
      background: $wb-ui-datepicker-header-background;
      border: $wb-ui-datepicker-header-border;
      border-radius: $wb-ui-datepicker-header-border-radius;
      box-sizing: content-box;
      height: $wb-ui-datepicker-header-height;
      margin: $wb-ui-datepicker-header-margin;
      padding: $wb-ui-datepicker-header-padding;
    }

    &-prev,
    &-next {

      @include hover {
        background: $wb-ui-datepicker-nav-hover-background;
        border: $wb-ui-datepicker-nav-hover-border;
        color: $wb-ui-datepicker-nav-hover-color;
        text-decoration: none;
      }
      background: $wb-ui-datepicker-nav-background;
      border: $wb-ui-datepicker-nav-border;
      border-radius: $wb-ui-datepicker-nav-border-radius;
      color: $wb-ui-datepicker-nav-color;
      cursor: pointer;
      height: $wb-ui-datepicker-nav-height;
      top: $wb-ui-datepicker-nav-spacing-y;
      width: $wb-ui-datepicker-nav-width;

      .ui-icon {
        align-items: center;
        background: none;
        display: flex;
        font-size: 0;
        height: 100%;
        margin: 0;
        position: static;
        text-indent: 0;
        width: 100%;

        /* stylelint-disable max-nesting-depth */
        &::before {
          font-size: $wb-ui-datepicker-nav-font-size;
          margin: 0 auto;
        }
        /* stylelint-enable max-nesting-depth */
      }
    }

    &-prev {
      left: $wb-ui-datepicker-nav-spacing-x;

      .ui-icon {
        /* stylelint-disable max-nesting-depth */
        &::before {
          @include icon($wb-ui-datepicker-nav-prev-icon);
        }
        /* stylelint-enable max-nesting-depth */
      }
    }

    &-next {
      right: $wb-ui-datepicker-nav-spacing-x;

      .ui-icon {
        /* stylelint-disable max-nesting-depth */
        &::before {
          @include icon($wb-ui-datepicker-nav-next-icon);
        }
        /* stylelint-enable max-nesting-depth */
      }
    }

    &-title {
      color: $wb-ui-datepicker-header-color;
      font-size: $wb-ui-datepicker-header-font-size;
      font-weight: $wb-ui-datepicker-header-font-weight;
      height: $wb-ui-datepicker-header-height;
      line-height: $wb-ui-datepicker-header-height;
      margin: 0 $wb-ui-datepicker-nav-width;
    }

    &-calendar {
      margin: 0;

      thead {
        background: $wb-ui-datepicker-week-background;
        border: $wb-ui-datepicker-week-border-y;
        border-left: $wb-ui-datepicker-week-border-x;
        border-right: $wb-ui-datepicker-week-border-x;
      }

      tbody {
        background: $wb-ui-datepicker-calendar-body-background;
      }

      th {
        color: $wb-ui-datepicker-week-color;
        font-size: $wb-ui-datepicker-week-font-size;
        font-weight: $wb-ui-datepicker-week-font-weight;
        height: $wb-ui-datepicker-week-size;
        padding: $wb-ui-datepicker-week-padding;
        text-align: $wb-ui-datepicker-week-text-align;
      }

      td {
        border: $wb-ui-datepicker-day-border-y;
        padding: $wb-ui-datepicker-day-spacing;
      }

      .ui-state-default {

        /* stylelint-disable max-nesting-depth */
        @include hover {
          background: $wb-ui-datepicker-day-hover-background;
          border: $wb-ui-datepicker-day-hover-border-y;
          border-left: $wb-ui-datepicker-day-hover-border-x;
          border-right: $wb-ui-datepicker-day-hover-border-x;
          color: $wb-ui-datepicker-day-hover-color;
          font-weight: $wb-ui-datepicker-day-hover-font-weight;
        }
        background: $wb-ui-datepicker-day-background;
        border: $wb-ui-datepicker-day-border-y;
        border-left: $wb-ui-datepicker-day-border-x;
        border-radius: $wb-ui-datepicker-day-border-radius;
        border-right: $wb-ui-datepicker-day-border-x;
        box-sizing: content-box;
        color: $wb-ui-datepicker-day-color;
        font-size: $wb-ui-datepicker-day-font-size;
        font-weight: $wb-ui-datepicker-day-font-weight;
        height: $wb-ui-datepicker-day-size;
        line-height: $wb-ui-datepicker-day-line-height;
        padding: $wb-ui-datepicker-day-padding;
        text-align: $wb-ui-datepicker-day-text-align;
        width: $wb-ui-datepicker-day-size;
        /* stylelint-enable max-nesting-depth */
      }

      .ui-state-highlight {
        background: $wb-ui-datepicker-day-highlight-background;
        border: $wb-ui-datepicker-day-highlight-border-y;
        border-left: $wb-ui-datepicker-day-highlight-border-x;
        border-right: $wb-ui-datepicker-day-highlight-border-x;
        color: $wb-ui-datepicker-day-highlight-color;
        font-weight: $wb-ui-datepicker-day-highlight-font-weight;
      }

      .ui-state-active {
        background: $wb-ui-datepicker-day-active-background;
        border: $wb-ui-datepicker-day-active-border-y;
        border-left: $wb-ui-datepicker-day-active-border-x;
        border-right: $wb-ui-datepicker-day-active-border-x;
        color: $wb-ui-datepicker-day-active-color;
        font-weight: $wb-ui-datepicker-day-active-font-weight;
      }

      .ui-state-disabled {
        opacity: $wb-ui-datepicker-day-disabled-opacity;

        /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
        .ui-state-default {
          background: $wb-ui-datepicker-day-disabled-background;
          color: $wb-ui-datepicker-day-disabled-color;
        }
        /* stylelint-enable selector-max-compound-selectors, max-nesting-depth */
      }
    }
  }
}
/* stylelint-enable selector-max-id */

/* stylelint-disable-next-line selector-max-id */
#ui-datepicker-div {
  z-index: $wb-ui-datepicker-z-index;
}

.comiseo-daterangepicker {
  .comiseo-daterangepicker-main {
    border-color: $gray-lighter;
    padding-bottom: $wb-ui-daterangepicker-main-padding-bottom;
  }

  .comiseo-daterangepicker-calendar {
    border-color: $gray-lighter;
  }

  .ui-datepicker {
    @include for-phone-only() {
      margin: $wb-ui-daterangepicker-margin-mobile;
    }

    border: 0;
    box-shadow: none;
    font-family: $wb-ui-datepicker-font-family;
    padding: $wb-ui-datepicker-padding;
  }

  .ui-datepicker-calendar {
    .ui-state-default {
      height: $wb-ui-daterangepicker-day-size;
      line-height: $wb-ui-daterangepicker-day-line-height;
      width: $wb-ui-daterangepicker-day-size;
    }
  }

  > .ui-helper-clearfix {
    padding: $wb-ui-daterangepicker-clearfix-padding;
  }

  &.ui-widget-content {
    font-family: unset;

    button {
      font-family: unset;
    }
  }

  .ui-widget-content {
    font-family: unset;

    .ui-state-focus {
      background-color: $gray-lightest;
      border: $wb-ui-datepicker-day-border-x;
    }

    .ui-priority-primary {
      font-weight: unset;
    }
  }

  .ui-menu {
    .ui-menu-item {
      /* stylelint-disable-next-line selector-max-compound-selectors */
      a {
        font-size: $font-size-base;
        padding: $wb-ui-daterangepicker-menu-item-padding;
      }
    }
  }

  .comiseo-daterangepicker-buttonpanel {
    font-size: $font-size-base;

    > button {
      margin-left: $wb-ui-daterangepicker-button-margin-left;
      margin-top: unset;
    }
  }

  /* stylelint-disable scss/at-extend-no-missing-placeholder */
  .ui-priority-primary {
    @extend .btn;
    @extend .btn-primary;
  }

  .ui-priority-secondary {
    @extend .btn;
    @extend .btn-default;
  }
  /* stylelint-enable scss/at-extend-no-missing-placeholder */
}

@include for-phone-only() {
  .comiseo-daterangepicker-presets,
  .comiseo-daterangepicker-calendar {
    display: block;
    height: unset;
  }

  .ui-menu {
    margin: $wb-ui-daterangepicker-ui-menu-margin-mobile;
  }

  .comiseo-daterangepicker-left {
    .comiseo-daterangepicker-calendar {
      border-right-width: 0;
    }
  }
}
