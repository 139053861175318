/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.panel {
  box-shadow: $wb-panel-box-shadow;
}

.panel-title {
  font-family: $wb-panel-title-font-family;
  font-size: $wb-panel-title-font-size;
  font-weight: $wb-panel-title-font-weight;
  line-height: $wb-panel-title-line-height;
}

legend.panel-heading {
  margin: $wb-panel-heading-legend-margin;
}
