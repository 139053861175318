/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Typography and basic elements styles.
 */

.h1,
h1 {
  line-height: $ssp-headline-h1-line-height;
}

.h2,
h2 {
  line-height: $ssp-headline-h2-line-height;
}

.h3,
h3 {
  line-height: $ssp-headline-h3-line-height;
  margin-bottom: $ssp-block-title-bottom-margin;
}

.h4,
h4 {
  line-height: $ssp-headline-h4-line-height;
  margin-bottom: $ssp-block-title-bottom-margin;
}

.h5,
h5 {
  line-height: $ssp-headline-h5-line-height;
}

.h6,
h6 {
  line-height: $ssp-headline-h6-line-height;
}

.btn {
  border-radius: $ssp-button-border-radius;
  font-size: $ssp-button-font-size;
  line-height: $ssp-button-line-height;
  padding: $ssp-button-padding;
  text-shadow: none;

  .input-group-btn & {
    font-size: $font-size-base;
  }

  .caret {
    margin-top: -2px;
  }

  .ajax-progress {
    margin-left: $ssp-button-icon-margin;
  }

  /* stylelint-disable selector-no-qualifying-type */
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    @include opacity($ssp-button-disabled-opacity);
  }

  /* stylelint-enable selector-no-qualifying-type */
}

.btn-primary {
  background-color: $ssp-button-background-color-primary;
  border-color: $ssp-button-border-color-primary;
  color: $ssp-button-text-color-primary;
}

.btn-sm {
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}

.icon-before {
  margin-right: $ssp-button-icon-margin-sm;
}

.icon-after {
  margin-left: $ssp-button-icon-margin-sm;
}
