/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Define lite dropdown styles (looks like a link not like a button).
 */

.dropdown-lite {
  .btn {
    border: 0;
    color: $ssp-brand-primary;
    font-size: inherit !important;

    @include hover {
      background: none !important;
      box-shadow: none;
      outline: none;
    }
  }

  .fa {
    font-size: inherit;
    vertical-align: middle;
  }

  &.open {
    .fa {
      transform: rotate(180deg);
    }
  }

  td & {
    .dropdown-menu {
      left: auto;
      min-width: 100px;
      right: 0;
    }
  }

  &--menu {
    min-width: 100px;
  }
}
