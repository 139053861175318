/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * SSP site related helper classes and
 * components
 */
.ssp-sideline-image {
  margin-top: $ssp-bootstrap-well-padding;

  @include for-tablet-landscape-up() {
    margin-right: $ssp-side-image-right-adjustment;
    margin-top: -#{($ssp-bootstrap-well-border-width + $ssp-bootstrap-well-padding - $ssp-side-image-top-adjustment)};
  }
}

.ssp-content-body {
  margin: $ssp-content-body-vertical-margin 0;
}

.ssp-content-description {
  color: $ssp-content-description-color;
  margin-bottom: $ssp-content-description-bottom-margin;
}

.ssp-well-prefix {
  margin-bottom: $ssp-well-prefix-bottom-spacing;

  i {
    font-size: $ssp-well-prefix-icon-size;
  }
}

// Icon related classes
.ssp-well-with-icon {
  display: flex;
  flex-direction: row;
}

.ssp-well-with-icon__content {
  flex-grow: 1;

  @include for-tablet-portrait-up() {
    display: flex;
    flex-direction: row;
  }
}

.ssp-icon {
  align-items: center;
  background-color: $ssp-icon-background-color;
  border-radius: $ssp-icon-radius;
  display: flex;
  flex-shrink: 0;
  height: $ssp-icon-outer-dimensions;
  justify-content: center;
  margin-right: $ssp-bootstrap-well-padding;
  width: $ssp-icon-outer-dimensions;

  svg {
    flex-basis: $ssp-icon-inner-dimensions;
  }
}

.ssp-icon--small {
  height: $ssp-icon-small-outer-dimensions;
  margin-right: $ssp-bootstrap-well-padding * (2/3);
  width: $ssp-icon-small-outer-dimensions;

  svg {
    flex-basis: $ssp-icon-small-inner-dimensions;
  }
}

.ssp-icon--success {
  background-color: $ssp-icon-success-background-color;
}

// Bootstrap well related classes
.ssp-well__content {
  flex-grow: 1;
}

.ssp-well__button {
  align-self: center;
  padding-left: $ssp-bootstrap-well-padding;

  @include for-phone-only() {
    margin-top: $ssp-bootstrap-well-padding;
    padding-left: 0;
  }
}

// page form box helper classes
.ssp-form-box {
  &.well {
    margin-bottom: 0;
  }

  .page-header {
    margin-bottom: $ssp-form-box-header-bottom-spacing;
  }
}

// Equal height helper classes
.ssp-equal-height-container {
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$ssp-view-card-bottom-margin;
  }

  .ssp-equal-height-cells {
    margin-top: $ssp-view-card-bottom-margin;
  }

  @include for-phone-only() {
    .row {
      flex-direction: column;
    }
  }
}

.ssp-equal-height-cells {
  margin-top: $ssp-view-card-bottom-margin;

  .well {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
  }
}

// Text
.ssp-text-large {
  font-size: $ssp-text-large;
}

// Labels
.ssp-label {
  &--Overdue,
  &--Failed {
    background-color: $ssp-label-danger;
  }

  &--Completed {
    background-color: $ssp-label-success;
  }

  &--None,
  &--Refunded,
  &--Chargeback,
  &--Cancelled {
    background-color: $ssp-label-default;
    text-transform: capitalize;
  }

  &--Ongoing,
  &--In.Progress,
  &--Partially.paid,
  &--Pending.refund,
  &--Pending {
    background-color: $ssp-label-warning;
  }
}

// Details page styling
.ssp-details-page-section {
  label {
    display: block;
  }

  .views-field {
    margin-bottom: $ssp-details-page-fields-bottom-margin;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: $ssp-details-page-paragraph-bottom-spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ssp-details-page-section__action-wrapper {
  margin-bottom: $ssp-details-page-action-bottom-spacing;

  .btn {
    width: 100%;
  }
}

.ssp-details-page-section__left {
  label {
    margin-bottom: $ssp-details-page-fields-left-labels-margin;
  }

  .views-field-image-URL {
    img {
      height: auto;
      width: 100%;
    }

    @include unwell('top-left-right');
    @include user-image-placeholder(
      calc(100% + 2 * #{$ssp-bootstrap-well-padding}),
      $ssp-details-page-no-image-icon-font-size,
      $ssp-details-page-no-image-icon-color,
      $ssp-details-page-no-image-background
    );
  }
}

.ssp-applicant-card__award {
  margin-right: $ssp-details-page-awards-text-right-spacing;
}

.ssp-details-page-section__sub-heading {
  align-items: center;
  display: flex;
  margin-bottom: $ssp-details-page-sub-heading-bottom-spacing - $ssp-applicant-card-footer-label-padding-y;

  .label {
    // Needs !important override as this needs to be worked with CiviCRM pages.
    display: inline-block !important;
    line-height: $ssp-applicant-card-footer-label-line-height;
    margin-bottom: $ssp-applicant-card-footer-label-padding-y !important;
  }

  .ssp-applicant-card__award {
    margin-bottom: $ssp-applicant-card-footer-label-padding-y;

    + span {
      display: block;
      line-height: 0;
    }
  }
}

.ssp-details-page-section__right {
  label {
    margin-bottom: $ssp-details-page-fields-right-labels-margin;
  }

  .views-field-nothing {
    .field-content {
      /* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
      > div {
        margin: $ssp-individiual-applicant-attachment-field-lists-margin;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      /* stylelint-enable max-nesting-depth, selector-max-compound-selectors */
    }
  }
}

.ssp-details-page-section-label-with-icon {
  padding-left: $ssp-details-page-fields-icon-adjustment;
  position: relative;

  > i {
    left: $ssp-details-page-fields-icon-x;
    position: absolute;
    top: $ssp-details-page-fields-icon-y;
  }
}

// Spinner CSS
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.view.ssp-loading {
  position: relative;
}

.ssp-ajax-loader {
  background: $ssp-spinner-backdrop-color;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.ssp-spinner-border {
  animation: spinner-border 0.75s linear infinite;
  border: #{$ssp-spinner-border} solid #{$ssp-spinner-color};
  border-radius: 50%;
  border-right-color: transparent;
  display: inline-block;
  height: $ssp-spinner-height;
  margin-top: $ssp-spinner-top-margin;
  vertical-align: text-bottom;
  width: $ssp-spinner-width;
}

.ssp-pagination-container {
  line-height: 0;

  .ajax-progress {
    display: none;
  }

  .progress-disabled {
    float: left;
  }
}

// SSP Update user fields - special label with checkbox
.ssp-webform-field-label-with-checkbox {
  display: flex;
  flex-wrap: wrap;

  > .control-label {
    display: none;
  }

  .form-checkbox {
    // This is hardcoded as this is hardcoded in bootstrap as well
    margin: 0 8px 0 0;
  }
}

// Top spacing separator on the left menu
.ssp-top-menu-separator {
  border-top: 1px solid $ssp-navbar-link-separator-border-color;
  margin-top: $ssp-navbar-link-separator-spacing !important;
  padding-top: $ssp-navbar-link-separator-spacing;
}
// Bottom spacing separator on the left menu
.ssp-bottom-menu-separator {
  border-bottom: 1px solid $ssp-navbar-link-separator-border-color;
  margin-bottom: $ssp-navbar-link-separator-spacing;
  padding-bottom: $ssp-navbar-link-separator-spacing;
}

// Page styles for aligning content vertically center.
.page-ssp--align-content-vertically {
  .main-container {
    > .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

// Page styles with left sidebar banner image
.page-ssp--with-sidebar-banner {
  &:not(.toolbar-drawer):not(.admin-menu) {
    .ssp-page-content {
      margin-left: 0;

      @include for-tablet-desktop-up() {
        margin-left: $ssp-sidebar-banner-width;
      }
    }
  }

  .webform-single-submit {
    .first-name,
    .last-name {
      flex-basis: inherit !important;
      width: inherit !important;
    }
  }

  .webform-client-form {
    .form-item {
      flex-basis: 100%;
      width: 100%;
    }
  }

  .ssp-hamburger-menu {
    display: none;
  }

  .ssp-fixed-sidebar {
    top: 0;
    width: $ssp-sidebar-banner-width;

    .ssp-logo {
      @include for-tablet-desktop-up() {
        background: $ssp-sidebar-banner-logo-background-color;
        border-radius: $ssp-sidebar-banner-logo-border-radius;
        left: $ssp-sidebar-banner-logo-spacing-x;
        padding: $ssp-sidebar-banner-logo-padding;
        position: fixed;
        top: $ssp-sidebar-banner-logo-spacing-y;
        z-index: 2;
      }
    }

    .ssp-sidebar-banner {
      /* stylelint-disable selector-max-compound-selectors */
      * {
        height: 100%;
      }
      /* stylelint-enable selector-max-compound-selectors */
    }

    @include for-tablet-desktop-down() {
      left: -#{$ssp-sidebar-banner-width};
    }
  }

  .ssp-header {
    .ssp-logo {
      @include for-tablet-desktop-down() {
        background: $ssp-sidebar-banner-logo-background-color;
        border-radius: $ssp-sidebar-banner-logo-border-radius;
        left: $ssp-sidebar-banner-logo-spacing-x;
        padding: $ssp-sidebar-banner-logo-padding;
        position: fixed;
        top: $ssp-sidebar-banner-logo-spacing-y;
      }
    }
  }
}

.aligned-row {
  display: flex;
  flex-wrap: wrap;

  > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
}

.h-100 {
  height: 100% !important;
}

.mt-4 {
  margin-top: $ssp-helper-mt-4;
}

.btn-wide {
  padding: $ssp-helper-btn-wide-padding;
}

.border-0 {
  border: 0 !important;
}

.text-truncate-3-lines {
  @include text-truncate(3);
}
