/**
 * @file
 *
 * Contains colors spacing and other
 * theme related sass variables.
 * Override the variables used in `ssp_bootstrap/sass/base/_variables.scss`
 */

// SSP Brand Variables
$brand-primary:                                #00577d !default;
$brand-success:                                #00577d !default;

$ssp-brand-ternary:                            #133443;
$ssp-brand-secondary-text-color:               #8f9bb3;
$brand-border-color:                           #d7e4f8;
$brand-primary-light:                          #edf1f7;
$border-radius-base:                           3px;

// Special color overrides
$ssp-footer-logo-width:                        129px;
$ssp-footer-logo-padding:                      20px;
$ssp-button-padding-y:                         10px;
$ssp-button-padding-x:                         10px;
$ssp-button-padding:                           $ssp-button-padding-y $ssp-button-padding-x;
$ssp-dropdown-box-box-shadow:                  0 5px 7px rgba(0, 0, 0, 0.2);
$ssp-button-disabled-opacity:                  0.5;

// Buttons
$ssp-cancel-button-border-color:               $brand-primary-light;
$ssp-cancel-button-color:                      #133443;
$ssp-cancel-button-background-color:           $brand-primary-light;

// Labels
$ssp-label-radius:                             2px;
$wb-label-font-weight:                         700 !default;
$btn_font_weight:                              700 !default;

// Header color
$ssp-header-text-line-height:                  40px;
$ssp-header-component-vertical-spacing:        0;
$ssp-header-component-vertical-spacing:        0;
$ssp-header-menu-border-width:                 0;
$ssp-header-vertical-padding:                  11px;
$ssp-header-padding:                           $ssp-header-vertical-padding 0;

// Navigation variables
$ssp-navbar-link-active-color:                 $brand-primary;
$ssp-navbar-link-active-background-color:      transparent;
$ssp-navbar-link-hover-color:                  $ssp-navbar-link-active-color;
$ssp-navbar-link-hover-background-color:       $ssp-navbar-link-active-background-color;

// Footer variables
$ssp-footer-background-color:                  #e0ebf2;

// Headlines
$ssp-headline-h6-line-height:                  1.5;
$ssp-bootstrap-well-headline-margin:           0 0 10px 0;

// No results behaviour
$ssp-no-result-image-width:                    260px;
$ssp-no-result-image-height:                   255px;

// Filters
$ssp-filters-select2-search-choice-close-spacing: 12px;
$wb-select2-input-right-caret-icon-color: #8f9bb3;
$wb-select2-input-close-icon-spacing-top: 13px;

// Bootstrap variables
$font-family-base:                             'Arial';
$font-size-base:                               16px;
$line-height-base:                             1.25; //20/16
$text-color:                                   $ssp-brand-ternary;
$headline-color:                               $ssp-brand-ternary;
$headline-font-family:                         'Miller', 'Times New Roman';
$well-border:                                  $brand-border-color;
$btn-default-color:                            $brand-primary;

$state-danger-text:                            #d9534f;
$state-danger-bg:                              #fee;

$input-height-base:                            40px;
$padding-base-vertical:                        10px;

$table-border-color:                           $brand-border-color;
$table-bg:                                     #fff;
$ssp-table-paynow-width:                       188px;
$table-cell-padding:                           10px;

$pagination-bg:                                transparent;
$pagination-border:                            transparent;

$nav-tabs-border-color:                        $brand-border-color;
$nav-tabs-active-link-hover-color:             $ssp-brand-ternary;
$nav-tabs-active-link-hover-border-color:      transparent;
$nav-tabs-active-link-hover-bg:                transparent;
$nav-link-hover-bg:                            $brand-primary-light;
$nav-tabs-link-hover-border-color:             $brand-primary-light;

$dropdown-link-color:                          $ssp-brand-ternary;

$dropdown-link-active-color:                   $ssp-brand-ternary;
$dropdown-link-hover-color:                    $ssp-brand-ternary;
$dropdown-link-hover-bg:                       $brand-primary-light;
$dropdown-border:                              rgba(0, 0, 0, 0.1);
$dropdown-divider-bg:                          #ddd;

$ssp-bootstrap-well-dashboard-block-bottom-spacing: 20px;

// Contact card
$ssp-contact-card-post-description-text-color: $ssp-brand-ternary;

// Details page
$ssp-details-page-fields-icon-y:               1px;
$ssp-details-page-no-image-background:         #d5e5ec;

// Panel variables
$ssp-panel-heading-font-family:                $headline-font-family;
$ssp-panel-heading-font-weight:                700;

// Applicant card
$ssp-applicant-card-padding: 15px;
$ssp-applicant-card-footer-top-spacing: 10px;
$ssp-applicant-card-footer-padding-y: 10px;
$ssp-applicant-card-title-bottom-spacing: 20px;
$ssp-applicant-card-action-position-right: 5px;
$ssp-applicant-card-action-position-top: 5px;
$ssp-applicant-card-action-button-color: #333;
$ssp-applicant-card-action-button-font-size: 11px;
$ssp-applicant-card-status-color: $text-color;
$ssp-applicant-card-status-status-font-weight: normal;
$ssp-applicant-card-status-icon-font-size: 11px;
$ssp-individiual-applicant-attachment-heading-margin: 0 0 15px 0;
$ssp-applicant-form-action-buttons-padding: $ssp-button-padding-y 20px;

// Date-time picker
$ssp-inline-timepicker-item-width: 106px;

// Modal
$ssp-modal-body-bg-color: #f8fbff;
$ssp-modal-close-button-positioning-top: 26px;
$ssp-modal-footer-close-button-min-width: 100px;
$ssp-modal-footer-close-button-border-color: $ssp-cancel-button-background-color;
$ssp-modal-footer-close-button-color: $ssp-cancel-button-color;
$ssp-modal-footer-close-button-background-color: $ssp-cancel-button-background-color;
$ssp-modal-footer-button-padding: $ssp-button-padding-y 20px;

// CiviCRM
$civicrm-button-icon-placement-y: 1.2rem;
