/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

// Views
.ssp-applicant-card {
  position: relative;

  &.well {
    padding: $ssp-applicant-card-padding;
  }
}

.ssp-applicant-card__actions {
  position: absolute;
  right: $ssp-applicant-card-action-position-right;
  top: $ssp-applicant-card-action-position-top;

  .btn {
    border: 0;
    color: $ssp-applicant-card-action-button-color;
    font-size: $ssp-applicant-card-action-button-font-size;
  }
}

.ssp-applicant-card__title {
  margin: 0 0 $ssp-applicant-card-title-bottom-spacing 0;
}

.ssp-applicant-card__status {
  font-size: $ssp-applicant-card-status-font-size;

  .fa {
    font-size: $ssp-applicant-card-status-icon-font-size;
  }

  span {
    color: $ssp-applicant-card-status-color;
    font-weight: $ssp-applicant-card-status-status-font-weight;
  }
}

.ssp-applicant-card__award {
  color: $ssp-applicant-card-award-color;
}

.ssp-applicant-card__footer {
  border-top: 1px solid $ssp-applicant-card-footer-border-color;
  margin-bottom: -#{$ssp-applicant-card-footer-label-padding-y};
  margin-left: -#{$ssp-applicant-card-padding};
  margin-right: -#{$ssp-applicant-card-padding};
  margin-top: $ssp-applicant-card-footer-top-spacing - $ssp-applicant-footer-action-top-adjustment;
  padding-left: $ssp-applicant-card-footer-padding-x;
  padding-right: $ssp-applicant-card-footer-padding-x;
  padding-top: $ssp-applicant-card-footer-padding-y;
  position: relative;
  top: $ssp-applicant-footer-action-top-adjustment;

  .label {
    display: inline-block;
    line-height: $ssp-applicant-card-footer-label-line-height;
    margin-bottom: $ssp-applicant-card-footer-label-padding-y;
  }
}

.civiawards__review-activity {
  > .clearfix {
    > .btn {
      padding: $ssp-applicant-form-action-buttons-padding;
    }

    > a {
      @include button-variant($ssp-applicant-form-cancel-button-color !important, $ssp-applicant-form-cancel-button-background-color, $ssp-applicant-form-cancel-button-border-color);
    }
  }
}
