/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Define responsive breakpoints helper mixins.
 */

@mixin for-phone-only {
  @media (max-width: $screen-sm - 1) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: $screen-sm) { @content; }
}
@mixin for-tablet-landscape-down {
  @media (max-width: $screen-md - 1) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: $screen-md) { @content; }
}
@mixin for-tablet-desktop-down {
  @media (max-width: $screen-lg - 1) { @content; }
}
@mixin for-tablet-desktop-up {
  @media (min-width: $screen-lg) { @content; }
}
