/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Fixed left user dashboard related
 * layout adjustments and styles.
 */

.ssp-fixed-sidebar-containers {
  @include for-phone-only() {
    margin-left: 0;

    body:not(.toolbar-drawer):not(.admin-menu) & {
      margin-left: 0;
    }
  }
}

.ssp-fixed-sidebar {
  background: $ssp-fixed-sidebar-background-color;
  box-shadow: $ssp-fixed-sidebar-box-shadow;
  display: block !important;
  flex-shrink: 0;
  left: 0;
  top: $ssp-vertical-spacing;
  width: $ssp-fixed-sidebar-width;

  body:not(.logged-in):not(.toolbar-drawer):not(.admin-menu) & {
    bottom: 0;
    position: absolute;
  }

  @include for-phone-only() {
    bottom: 0;
    left: -#{$ssp-fixed-sidebar-width};
    position: absolute;
    top: $ssp-vertical-spacing;
    transition: all #{$ssp-animation-duration} ease-in;
    z-index: 10;

    &.ssp-slide-open {
      left: 0;
    }

    body:not(.toolbar-drawer):not(.admin-menu) & {
      top: $ssp-vertical-spacing / 2;
    }
  }
}

.ssp-backdrop {
  @include for-phone-only() {
    background: rgba($ssp-brand-gray-dark, $ssp-backdrop-tranparency);
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all #{$ssp-animation-duration} ease-in;
    width: 100%;
    z-index: 5;

    &.ssp-visible {
      opacity: 1;
      pointer-events: all;
    }
  }
}
