/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.ssp-certificates__download-menu {
  width: max-content;
}

.ssp-certificates__download {
  .btn {
    border: 0;
    color: $ssp-applicant-card-action-button-color;
    font-size: $ssp-applicant-card-action-button-font-size;
  }
}
