/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Bootstrap layout overrides.
 *
 * Copied from bootstrap and changes width to max-width
 * to make the layout adaptable to screen (when the left side bar is present).
 *
 * The left sidebar takes up some width thereby leaving the remaining part of
 * the screen to have lesser width and therefore bootstrap container class
 * using hard width doesn't adapt with screensize. Changing them to max-width
 * fixes the issue.
 */
.container {
  width: 100%; // Override width to span 100%.
}

@include for-tablet-portrait-up() {
  .container {
    max-width: $ssp-container-width-portrait;
  }
}

@include for-tablet-landscape-up() {
  .container {
    max-width: $ssp-container-width-landscape;
  }
}

@include for-tablet-desktop-up() {
  .container {
    max-width: $ssp-container-width-desktop;
  }
}
