/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Generic website html and page related styles.
 *
 * Defines basic skeleton styles.
 */

html,
body {
  min-height: 100%;
}

html {
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ssp-page-container {
  display: inline-flex;
  min-height: calc(100vh - #{$ssp-header-height});

  body:not(.toolbar-drawer):not(.admin-menu) & {
    min-height: 100vh;
  }

  .ssp-page-content {
    flex-direction: column;
  }
}

.main-container {
  padding-top: $ssp-vertical-spacing;
}

.ssp-page-content {
  display: flex;
  flex-grow: 1;

  .main-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    > .container {
      flex-grow: 1;
    }
  }
}

.region-content {
  .block {
    margin-bottom: $ssp-bootstrap-well-dashboard-block-bottom-spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
