/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/*
 * @file
 * Represents a node like article, news, etc - some page for reading.
 */
.ssp-article {
  .content {
    margin-bottom: $ssp-details-page-content-padding;

    h1 {
      margin: $ssp-details-page-header-margin;
    }
  }

  .submitted {
    color: $ssp-brand-gray-light;
    margin: $ssp-horizontal-spacing 0;
  }

  .field-name-body {
    /* stylelint-disable selector-max-compound-selectors */
    .field-item {
      *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
