/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Generic site header styles and layout changes
 */

.ssp-header {
  background-color: $ssp-header-background-color;
  border-color: $ssp-header-border-color;
  border-radius: 0;
  color: $ssp-header-text-color;
  margin: 0;
  min-height: $ssp-header-total-height;
  padding: $ssp-header-padding;
  position: relative;
  z-index: 10;

  .navbar-brand {
    color: $ssp-header-text-color;
  }

  .navbar-toggle {
    border: 0;
    flex-grow: 1;
    height: $ssp-header-component-vertical-spacing * 2 + $ssp-header-text-line-height;
    margin: 0 $ssp-header-logo-right-spacing 0 0;
    padding: 0;

    @include hover {
      background-color: transparent;
    }
  }

  &.ssp-banner {
    background-color: $ssp-fixed-sidebar-background-color;
    border: 0;
    box-shadow: $ssp-fixed-sidebar-box-shadow;

    &.ssp-header-transparent {
      background-color: transparent;
    }
  }

  .btn {
    border-width: $ssp-header-menu-border-width;
    font-weight: $ssp-header-button-font-weight;
    line-height: $ssp-header-text-line-height;
    padding-bottom: $ssp-header-component-vertical-spacing - $ssp-header-menu-border-width;
    padding-top: $ssp-header-component-vertical-spacing - $ssp-header-menu-border-width;
  }

  @include for-phone-only() {
    .container-fluid {
      display: flex;
    }

    .ssp-logo {
      flex-grow: 1;

      img {
        height: auto;
        max-height: $ssp-fixed-sidebar-logo-mobile-max-height;
        max-width: 100%;
      }
    }

    .view-ssp-user-login-form,
    .view-ssp-user-register-page {
      .field-content {
        font-size: 0;
      }
    }

    .btn {
      font-size: $ssp-button-sm-font-size;
      max-width: $ssp-header-mobile-btn-max-width;
      overflow: hidden;
    }

    /* stylelint-disable selector-max-id */
    #block-menu-menu-ssp-user-menu {
      .btn {
        font-size: 0;

        /* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
        &::before {
          font-size: $ssp-header-mobile-btn-user-icon-size;
          vertical-align: top;

          @include fa-icon($ssp-header-mobile-btn-user-icon);
        }

        .caret,
        .fa {
          margin: $ssp-header-mobile-btn-caret-margin;
          vertical-align: middle;
        }
        /* stylelint-enable max-nesting-depth, selector-max-compound-selectors */
      }
    }
    /* stylelint-enable selector-max-id */
  }
}

.ssp-site-info-container {
  border-color: $ssp-header-border-color;
  border-radius: 0;
  color: $ssp-header-text-color;
  margin: 0;
  min-height: $ssp-header-total-height;
  padding: $ssp-header-padding;
}

.ssp-logo {
  line-height: $ssp-header-text-line-height;
  margin: 0 $ssp-header-logo-right-spacing 0 0;
  padding: $ssp-header-component-vertical-spacing 0;

  img {
    height: auto;
    max-width: $ssp-fixed-sidebar-logo-max-width;
  }
}

.region-ssp-header-menu {
  // Copy styles from bootstrap/dropdown scss partial,
  // in order to reset them so that active styles can be overridden.
  .dropdown-menu {
    /* stylelint-disable selector-max-compound-selectors */
    .active > a {
      background-color: inherit;
      color: $dropdown-link-color;

      @include hover {
        background-color: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
      }
    }
    /* stylelint-enable */
  }
}
