/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * My organisation page related styles.
 */

.view-ssp-organisations-members {
  .checkbox,
  .radio {
    margin: 0;
  }

  .table-responsive {
    + .form-actions {
      margin-top: $ssp-table-form-action-button-spacing-y;
    }
  }

  .views-field-id {
    min-width: $ssp-table-organisations-field-id-width;
  }

  .views-field-phone {
    min-width: $ssp-table-organisations-field-phone-width;
  }

  .views-field-nothing {
    min-width: $ssp-table-organisations-field-optin-width;
  }
}

.view-ssp-organisations-detail-page {
  .organisation-edit-details-link {
    margin: $ssp-button-icon-margin;
  }
}

.view-ssp-organisations-listing {
  .dropdown.remove-self-org {
    .btn.dropdown-toggle {
      border: 0;
      color: $ssp-applicant-card-action-button-color;
      float: right;
      position: absolute;
      right: $ssp-side-image-right-adjustment;
      top: calc(#{$ssp-side-image-top-adjustment * 6});
    }

    .dropdown-menu {
      left: calc(#{$ssp-content-body-vertical-margin * 5});
      top: calc(#{($ssp-side-image-top-adjustment * 4) + 2});
    }
  }

  .request-join-button {
    margin-bottom: $ssp-details-page-fields-bottom-margin;
  }

  .ssp-contact-card {
    min-height: $ssp-contact-card-well-height;
  }

}

.ssp-core-organisations-manage-member-form {
  .form-item-manage-member-relationships {
    max-height: $ssp-modal-manage-member-form-content-height;
    overflow-x: hidden;
    overflow-y: auto;

    .form-checkboxes {
      @include for-tablet-portrait-up() {
        column-count: 2;
      }
    }
  }
}

.webform-client-form {
  .add-member-webform-helptext {
    background: $ssp-warning-bg;
    padding: $ssp-button-padding-x;

    ul {
      margin-bottom: 0;
      padding-left: $ssp-cpd-list-item-padding-x;
    }
  }

  .wb-component-civicrm-contact-fieldset {
    .first-name {
      @include for-phone-only() {
        flex-basis: $ssp-cpd-panel-body-width;
      }
    }
  }

  .panel {
    @include for-phone-only() {
      margin: 0 !important;
    }
  }

  .webform-submit {
    @include for-phone-only() {
      width: $ssp-cpd-panel-body-width;
    }
  }
}

.page-ssp-organisations {
  .modal-default {
    top: $ssp-table-organisations-field-id-width !important;
  }

  .modal-body {
    overflow: scroll !important;
  }
}

.view-ssp-organisations-manage-members-request {
  margin-bottom: $ssp-vertical-spacing;
}

.ctools-modal-dialog {
  .modal-body {
    @include for-phone-only() {
      overflow-x: hidden;
      overflow-y: auto;
      padding: $ssp-table-mobile-prefix-space;
      width: $ssp-cpd-panel-body-width !important;
    }
  }
}

a.cw-organisation-news-view {
  border: $ssp-header-border solid transparent;
  border-radius: $ssp-button-padding-y;
  cursor: pointer;
  display: inline-block;
  font-weight: $ssp-font-weight-bold;
  padding: $ssp-button-padding-y $ssp-icon-quot-padding-mobile;
  text-decoration: none;
}

.modal-open {
  .node-cw_news-form,
  .node-cw_event-form,
  .node-cw_opportunity-form {
    .form-type-select {
      width: $ssp-cpd-managed-file-width !important;
    }

    fieldset.filter-wrapper {
      display: none !important;
    }
  }

  .button.browse {
    background: linear-gradient(to bottom, $ssp-my-organisations-white-color $ssp-my-organisations-linear-gradient-null, $ssp-my-organisations-gray-color $ssp-my-organisations-linear-gradient-full);
    border: $ssp-header-border solid $ssp-cancel-button-border-color;
    border-radius: $ssp-my-organisations-border-radius;
    color: $ssp-brand-gray-base !important;
    margin-left: $ssp-header-border;
    margin-top: $ssp-header-border;
    padding: $ssp-button-padding-y $ssp-filters-icon-adjustment-spacing;
    text-decoration: none;
    width: $ssp-contact-card-well-height;
  }
}

.cw-organisation-news-view {
  .views-exposed-widgets {
    display: flex !important;
    padding-bottom: $ssp-horizontal-spacing !important;
  }

  .views-reset-button {
    .btn {
      position: relative;
      top: $ssp-icon-quot-padding;
    }
  }

  .views-widget-filter-created_1 {
    width: $ssp-filters-date-field-width !important;

    .views-widget {
      display: flex !important;
    }
  }

  .form-item-news-created-1,
  .form-item-opportunity-created-1,
  .form-item-event-created-1 {
    padding-right: $ssp-memberships-list-item-padding !important;
    width: $ssp-filters-date-field-width !important;

    input {
      cursor: pointer;
    }
  }

  .form-item-news-created-1::before,
  .form-item-opportunity-created-1::before,
  .form-item-event-created-1::before {
    content: '\f073';
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-weight: $ssp-my-organisations-font-weight;
    left: $ssp-my-organisations-date-left;
    padding-right: $ssp-my-organisations-date-padding-right;
    pointer-events: none;
    position: absolute;
    top: $ssp-button-padding-y;
    vertical-align: middle;
  }

  .views-widget-filter-created_2 {
    padding-top: $ssp-my-organisations-date-padding-top !important;
    width: $ssp-filters-date-field-width !important;
  }

  .form-item-news-created-2::before,
  .form-item-opportunity-created-2::before,
  .form-item-event-created-2::before {
    content: '\f073';
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-weight: $ssp-my-organisations-font-weight;
    padding-right: $ssp-my-organisations-date-padding-right;
    pointer-events: none;
    position: absolute;
    right: $ssp-my-organisations-date-right;
    top: $ssp-button-padding-y;
    vertical-align: middle;
  }

  .form-item-news-created-2,
  .form-item-opportunity-created-2,
  .form-item-event-created-2 {
    width: $ssp-filters-date-field-width !important;

    .control-label {
      display: none;
    }

    input {
      cursor: pointer;
    }
  }
}
