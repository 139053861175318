/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * SSP listing view on the dashboard.
 */

.ssp-dashboard-listing {
  .views-bootstrap-grid-plugin-style {
    .well {
      flex-direction: row;
    }

    .row {
      margin-top: 0;
    }

    .ssp-equal-height-cells {
      margin-top: 0;

      @include for-tablet-landscape-down() {
        margin-bottom: $ssp-bootstrap-well-dashboard-block-bottom-spacing;
      }
    }

    @include for-tablet-landscape-down() {
      margin-bottom: -#{$ssp-bootstrap-well-dashboard-block-bottom-spacing};
    }
  }
}
