/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.table {
  border-bottom: $wb-table-bottom-border-width solid $wb-table-bottom-border-color;
  border-top: $wb-table-top-border-width solid $wb-table-top-border-color;

  &.webform-grid {
    overflow: hidden;
  }

  td.checkbox,
  td.radio,
  th.checkbox,
  th.radio {
    display: table-cell;
    text-align: $wb-table-options-text-alignment;
    @if $wb-table-options-in-one-line {
      white-space: nowrap;
    }

    > .checkbox,
    > .radio {
      margin: $wb-table-options-margin;
    }
  }

  > thead {
    > tr {
      /* stylelint-disable selector-max-compound-selectors */
      > th {
        background-color: $wb-table-heading-background-color;
        border-width: $wb-table-heading-bottom-border-width;
        padding: $wb-table-heading-padding;
      }
      /* stylelint-enable selector-max-compound-selectors */
    }
  }
}
