/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.webform-progressbar {
  &,
  .webform-client-form > div > &,
  .webform-client-form .well > & {
    flex-basis: $wb-webform-progressbar-width;
    margin: 0 auto;
  }
}

.webform-progressbar-outer {
  background: none;
  border: 0;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: $wb-webform-progressbar-outer-item-text-justify;
  margin: $wb-webform-progressbar-margin;
  position: relative;

  &::before {
    background: $wb-webform-progressbar-baseline-color;
    content: '';
    height: $wb-webform-progressbar-baseline-height;
    left: $wb-webform-progressbar-page-size/2;
    position: absolute;
    top: $wb-webform-progressbar-page-size/2 - $wb-webform-progressbar-baseline-height/2;
    width: calc(100% - #{$wb-webform-progressbar-page-size});
    z-index: 0;
  }
}

.webform-progressbar-inner {
  display: none;
}

.webform-progressbar-page {
  background: none;
  border: 0;
  border-radius: 0;
  color: $wb-webform-progressbar-page-item-color;
  font-weight: $wb-webform-progressbar-page-item-font-weight;
  height: auto;
  margin: 0;
  padding: $wb-webform-progressbar-page-item-padding;
  position: static;
  width: auto;
  z-index: 1;

  + .webform-progressbar-page {
    margin: $wb-webform-progressbar-page-sibling-item-margin;
  }

  .webform-progressbar-page-number {
    background: $wb-webform-progressbar-page-background;
    border: $wb-webform-progressbar-page-border;
    border-radius: $wb-webform-progressbar-page-border-radius;
    box-sizing: content-box;
    color: $wb-webform-progressbar-page-color;
    display: block;
    font-size: $wb-webform-progressbar-page-font-size;
    height: $wb-webform-progressbar-page-size;
    line-height: $wb-webform-progressbar-page-size;
    margin: $wb-webform-progressbar-page-item-number-margin;
    overflow: hidden;
    width: $wb-webform-progressbar-page-size;
  }

  .webform-progressbar-page-label {
    color: $wb-webform-progressbar-page-label-color;
    display: block;
    height: $wb-webform-progressbar-page-label-height;
    margin: $wb-webform-progressbar-page-label-margin;
    position: static;
  }

  &.completed {
    background-color: $wb-webform-progressbar-page-item-completed-background;

    .webform-progressbar-page-number {
      background: $wb-webform-progressbar-page-completed-background;
      border: $wb-webform-progressbar-page-completed-border;
      color: $wb-webform-progressbar-page-completed-color;
      font-size: 0;

      /* stylelint-disable max-nesting-depth */
      &::before {
        @include icon($wb-webform-progressbar-page-completed-icon);
        font-size: $wb-webform-progressbar-page-completed-icon-size;
        line-height: $wb-webform-progressbar-page-size;
      }
      /* stylelint-enable */
    }
  }

  &.current {
    background: $wb-webform-progressbar-page-item-current-background-color;
    color: $wb-webform-progressbar-page-item-current-color;

    .webform-progressbar-page-number {
      background: $wb-webform-progressbar-page-current-background;
      border: $wb-webform-progressbar-page-current-border;
      color: $wb-webform-progressbar-page-current-color;
    }
  }
}
