/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

%select2-drop {
  background-color: $wb-select2-dropdown-background-color;
  border: $wb-select2-dropdown-border;
  border-radius: $wb-select2-dropdown-border-radius;
  box-shadow: $wb-select2-dropdown-box-shadow;
  margin-top: $wb-select2-dropdown-top-margin;
}

%select2-result-label {
  font-size: $wb-select2-dropdown-result-label-font-size;
  line-height: $wb-select2-dropdown-result-label-line-height;
  padding: $wb-select2-dropdown-result-label-padding;
}

%select2-search-choice {
  background: $wb-select2-multiselect-option-background;
  border: $wb-select2-multiselect-option-border;
  border-radius: $wb-select2-multiselect-option-border-radius;
  font-family: $wb-select2-multiselect-option-font-family;
  font-size: $wb-select2-multiselect-option-font-size;
  line-height: $wb-select2-multiselect-option-line-height;
  margin: $wb-select2-multiselect-option-spacing 0 $wb-select2-multiselect-option-spacing $wb-select2-multiselect-option-spacing;
  padding: $wb-select2-multiselect-option-padding;
}

%select2-search-choice-close {
  background: transparent !important;
  bottom: 0;
  font-size: inherit;
  height: auto;
  left: 0;
  line-height: 0;
  right: 0;
  top: 0;
  width: auto;

  &::before {
    @include icon($wb-select2-input-close-icon);
    color: $wb-select2-multiselect-close-icon-color;
    font-size: $wb-select2-multiselect-close-icon-size;
    left: $wb-select2-multiselect-close-left-offset;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.form-select {
  padding-right: 2 * $wb-select2-input-right-caret-icon-padding-x +
    $wb-select2-input-right-caret-icon-width +
    $wb-select2-input-right-caret-icon-spacing-right;

  .select2-container + & {
    // retaining listbox effect for hidden selectboxes
    appearance: listbox;
    min-width: $wb-select2-multiselect-option-min-width;
  }
}

.select2-container {
  &,
  &[class*='crm-'] {
    display: block;
    // override the incorrect logic of
    // select2 container calculation
    width: 100% !important;
  }

  &.select2-container-active {
    .select2-choice {
      box-shadow: $wb-select2-input-active-box-shadow;
    }
  }

  &.select2-drop-above {
    .select2-choice {
      background: $wb-select2-input-background;
      border-color: $wb-select2-input-border-color;
    }
  }

  .select2-choice {

    @include form-control-focus;
    background: $wb-select2-input-background;
    border-color: $wb-select2-input-border-color;
    border-radius: $wb-select2-input-border-radius;
    box-shadow: $wb-form-control-box-shadow;
    height: $wb-select2-input-height;
    padding: $wb-select2-input-padding;

    > .select2-chosen {
      color: $wb-select2-input-selected-color;
      line-height: $wb-input-line-height;
      margin-right: 2 * $wb-select2-input-right-caret-icon-padding-x +
        $wb-select2-input-right-caret-icon-width +
        $wb-select2-input-right-caret-icon-spacing-right;
      padding: $wb-select2-input-selected-padding;
    }

    .select2-arrow {
      background: $wb-select2-input-right-caret-icon-background-color;
      border: 0;
      padding: 0 $wb-select2-input-right-caret-icon-padding-x;
      right: $wb-select2-input-right-caret-icon-spacing-right;
      width: 2 * $wb-select2-input-right-caret-icon-padding-x + $wb-select2-input-right-caret-icon-width;

      /* stylelint-disable selector-max-compound-selectors */
      b {
        border-bottom: 0;
        border-left: ($wb-select2-input-right-caret-icon-width / 2) solid transparent;
        border-right: ($wb-select2-input-right-caret-icon-width / 2) solid transparent;
        border-top: $wb-select2-input-right-caret-icon-height solid $wb-select2-input-right-caret-icon-color;
        height: 0;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0;
      }
      /* stylelint-enable selector-max-compound-selectors */
    }

    abbr {
      background: transparent !important;
      bottom: 0;
      font-size: inherit;
      height: auto;
      line-height: 0;
      right: 2 * $wb-select2-input-right-caret-icon-padding-x +
        $wb-select2-input-right-caret-icon-width +
        $wb-select2-input-right-caret-icon-spacing-right;
      top: 0;
      width: auto;
      width: 2 * $wb-select2-input-close-icon-padding-x + $wb-select2-input-close-icon-size;

      &::before {
        @include icon($wb-select2-input-close-icon);
        color: $wb-select2-input-close-icon-color;
        font-size: $wb-select2-input-close-icon-size;
        left: 50%;
        line-height: 1;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.select2-allowclear {
    .select2-choice {
      .select2-chosen {
        margin-right: 2 * $wb-select2-input-right-caret-icon-padding-x +
          $wb-select2-input-right-caret-icon-width +
          $wb-select2-input-right-caret-icon-spacing-right +
          2 * $wb-select2-input-close-icon-padding-x +
          $wb-select2-input-close-icon-size;
      }
    }
  }
}

.select2-drop {
  @extend %select2-drop;

  &.select2-drop-active {
    &,
    &.select2-drop-above {
      border: $wb-select2-dropdown-border;
      border-radius: $wb-select2-dropdown-border-radius;
      box-shadow: $wb-select2-dropdown-box-shadow;
      margin-top: $wb-select2-dropdown-top-margin;
    }

    &.select2-drop-above {
      box-shadow: $wb-select2-dropdown-box-shadow-above;
      margin-top: 0;
      transform: translate(0, -1 * $wb-select2-dropdown-bottom-margin);
    }
  }

}

.select2-results {
  margin: $wb-select2-dropdown-results-margin;
  padding: $wb-select2-dropdown-results-padding;

  .select2-result {
    &.select2-highlighted {
      background-color: $wb-select2-dropdown-result-highlighted-background-color;
      color: inherit;
    }
  }

  .select2-result-label {
    @extend %select2-result-label;
  }
}

.select2-dropdown-open {
  &,
  &.select2-drop-above {
    .select2-choices,
    .select2-choice {
      background: $input-bg;
    }
  }

}

.select2-container-multi {
  .select2-choices {
    background: $input-bg;
    border-color: $wb-select2-multiselect-border-color;
    border-radius: $wb-select2-multiselect-border-radius;
    box-shadow: $wb-form-control-box-shadow;

    .select2-search-choice {
      @extend %select2-search-choice;
    }

    .select2-search-choice-close {
      @extend %select2-search-choice-close;
    }

    .select2-search-field {
      float: none;
    }

    .select2-search-choice,
    .search-choice {
      float: left;

      /* stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type */
      html[dir='rtl'] & {
        float: right;
      }
      /* stylelint-enable selector-max-compound-selectors, selector-no-qualifying-type */
    }
  }
}

.select2-search {
  padding: $wb-select2-search-box-input-margin;

  input {
    /* stylelint-disable scss/at-extend-no-missing-placeholder */
    @extend .form-control;
    /* stylelint-enable scss/at-extend-no-missing-placeholder */
    background: transparent !important;
    padding-right: 2 * $wb-select2-search-box-input-icon-right-shift + $wb-select2-search-box-input-icon-size;
  }

  &::after {
    @include icon('\e8bd');
    font-size: $wb-select2-search-box-input-icon-size;
    position: absolute;
    right: $wb-select2-search-box-input-icon-right-shift + 1px + $wb-select2-search-box-input-margin;
    top: 50%;
    transform: translate(0, -50%);
  }
}
