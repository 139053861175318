/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 * Contains styles for left sidebar banner image block.
 */

.ssp-sidebar-banner {
  .ssp-sidebar-banner__body {
    align-items: center;
    color: $ssp-sidebar-banner-text-color;
    display: flex;
    font-size: $ssp-sidebar-banner-text-font-size;
    left: 0;
    line-height: $ssp-sidebar-banner-text-line-height;
    padding: $ssp-sidebar-banner-text-padding;
    position: absolute;
    top: 0;
    z-index: 6;

    > * {
      height: auto !important;
    }
  }

  .ssp-sidebar-banner__image {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;

    &::before {
      background-color: $ssp-sidebar-banner-tint-color;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    img {
      left: 50%;
      max-width: initial;
      position: relative;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;

      @include for-small-height-screens() {
        height: auto !important;
        width: 100%;
      }
    }
  }
}
