/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.form-type-password {
  &.has-feedback {
    .control-label {
      display: flex;

      .label {
        line-height: $ssp-form-password-label-height;
        margin-left: $ssp-form-password-label-spacing-x;
      }
    }
  }

  .form-control-feedback {
    margin-right: $ssp-form-password-feedback-icon-spacing-x;
  }

  .progress {
    margin-bottom: 0;
  }

  &.has-error,
  &.has-success,
  &.has-warning {
    input,
    .progress {
      width: calc(100% - #{$ssp-form-password-feedback-icon-width});
    }

    .control-label {
      display: flex;

      .label {
        line-height: $ssp-form-password-label-height;
        margin-left: $ssp-form-password-label-spacing-x;
      }
    }
  }
}

.form-type-password-confirm {
  .row {
    .help-block {
      padding: $ssp-bootstrap-well-padding;
    }
  }
}
