/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.webform-submit,
.webform-next {
  margin-left: auto;
}

.webform-previous,
.webform-next {
  align-items: center;
  display: flex;
}

.webform-previous {
  &::before {
    @include icon($wb-form-submit-previous-icon);
    font-size: $wb-form-submit-icon-size;
    padding-right: $wb-form-submit-icon-spacing-x;
  }
}

.webform-next {
  &::after {
    @include icon($wb-form-submit-next-icon);
    font-size: $wb-form-submit-icon-size;
    padding-left: $wb-form-submit-icon-spacing-x;
  }
}
