/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.view-ssp-news {
  .ssp-equal-height-container {
    + .ssp-full-card {
      // adjusting the extra spacing when ssp-equal-height-cotnainer
      // is used
      margin-top: $ssp-view-card-bottom-margin;
    }
  }

  &.view-display-id-page_listing {
    .view-content {
      > .ssp-full-card {
        /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
        .ssp-full-card__content {
          display: flex;
          flex-direction: column;
        }

        .ssp-full-card__summary {
          flex-grow: 1;
        }
        /* stylelint-enable */
      }
    }

    .ssp-equal-height-cells:not(.col-md-6) {
      h4 {
        margin-bottom: $ssp-full-card-subtitle-line-bottom-spacing;
      }
    }
  }

  &.view-display-id-block_news_updates {
    .ssp-full-card__content {
      display: flex;
      flex-direction: column;
    }

    .ssp-full-card__other-fields {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .ssp-contact-card__description {
      flex-grow: 1;
    }
  }

  @include for-tablet-landscape-down() {
    // for tablet make all horizontal except the big one
    .ssp-equal-height-cells:not(.col-md-6) {
      .well {
        flex-direction: row;
      }
    }
  }

  @include for-phone-only() {
    // on mobile make all to horizontal the top three
    .ssp-equal-height-cells:not(.col-md-6) {
      .well {
        flex-direction: column;
      }
    }

    // On all other events.
    .ssp-full-card {
      flex-direction: column;
    }

    .ssp-full-card__left-image {
      border-radius: $ssp-border-radius-default $ssp-border-radius-default 0 0;
      margin: -#{$ssp-bootstrap-well-padding} -#{$ssp-bootstrap-well-padding} #{$ssp-bootstrap-well-padding} -#{$ssp-bootstrap-well-padding};
      width: calc(100% + #{$ssp-bootstrap-well-padding * 2});

      img {
        height: auto;
        width: 100%;
      }
    }

    &.view-display-id-block_news_updates {
      .well {
        flex-direction: column;
      }
    }
  }
}
