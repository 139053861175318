/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Contains mixin blocks.
 */

@mixin text-truncate($number-of-lines: 1) {
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-box-orient: vertical;
  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box;
  -webkit-line-clamp: $number-of-lines;
  overflow: hidden;
  text-overflow: ellipsis;
}
