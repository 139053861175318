/**
 * @file
 *
 * This file contains core drupal components
 * related overrides
 *
 * @todo: Future plans -
 * Audit  the changes to filter out the styling
 * and incorporate basic color and spacing
 * changes inside core SSP bootstrap theme
 * so that these can be directly configured
 * through css variable for other sub themes.
 */
.block-title {
  margin: #{$line-height-computed} 0;
}

.views-exposed-form {
  label {
    font-family: $headline-font-family;
  }

  .well {
    border: 0;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }

  .views-reset-button {
    .btn {
      background-color: #edf1f7;
      border: 1px solid #edf1f7;
      color: $ssp-brand-ternary;
      font-size: $ssp-headline-h6-font-size;
      font-weight: bold;
      line-height: $ssp-headline-h6-line-height;
      padding: #{$line-height-computed / 2} 15px;
    }
  }
}

/* Special override for the top menu icon */
/* stylelint-disable selector-max-id */
#block-menu-menu-ssp-user-menu {
  .btn {
    .fa {
      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 15px;
        position: relative;
        top: 2px;
      }
    }
  }

  .open {
    .btn {
      /* stylelint-disable selector-max-compound-selectors, max-nesting-depth*/
      .fa:last-child {
        &::before {
          content: '\f077'; // Chevron up unicode
        }
      }
      /* stylelint-enable selector-max-compound-selectors, max-nesting-depth*/
    }
  }
}
/* stylelint-enable selector-max-id */
