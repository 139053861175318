/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
form {
  .webform-component.static {
    /* stylelint-disable scss/at-extend-no-missing-placeholder */
    @extend .form-group;
    /* stylelint-enable scss/at-extend-no-missing-placeholder */

    li.token-input-token {
      background-color: $wb-autocomplete-input-static-token-background-color;
      color: $wb-autocomplete-input-static-token-color;
    }
  }

  ul.token-input-list {
    border-color: $wb-autocomplete-input-border-color;
    border-radius: $wb-autocomplete-input-border-radius;
    box-shadow: $wb-form-control-box-shadow;
    max-width: 100%;
    min-height: $wb-autocomplete-input-height;
    position: relative;

    &::before {
      @include icon($wb-autocomplete-search-icon-code);

      font-size: $wb-autocomplete-search-icon-font-size;
      position: absolute;
      right: $wb-input-padding-x;
      top: 50%;
      transform: translate(0, -50%);
    }

    li {
      &.token-input-token,
      &.token-input-input-token input {
        font-size: $wb-input-font-size;
        font-weight: $wb-autocomplete-input-font-weight;
        margin: $wb-autocomplete-input-inset-margin;
        position: relative;
      }

      &.token-input-input-token {
        padding-right: $wb-autocomplete-search-icon-font-size + $wb-autocomplete-input-padding-x * 2;

        input {
          padding: $wb-autocomplete-input-padding-y 0 $wb-autocomplete-input-padding-y $wb-autocomplete-input-padding-x;
          width: auto;
        }
      }

      &.token-input-token {
        background: $wb-autocomplete-input-token-background-color;
        color: $wb-autocomplete-input-token-text-color;
        padding: $wb-autocomplete-input-padding-y $wb-autocomplete-input-padding-x - $wb-autocomplete-input-inset-margin;

        .token-input-delete-token {
          color: $wb-autocomplete-input-token-delete-icon-color;
        }
      }

      &.token-input-selected-token {
        background: $wb-autocomplete-input-selected-token-background-color;
        color: $wb-autocomplete-input-selected-token-color;
      }
    }
  }
}

div.token-input-dropdown {
  font-family: $wb-autocomplete-dropdown-font-family;
  padding: $wb-autocomplete-dropdown-padding;
  z-index: $wb-autocomplete-dropdown-z-index;

  > p {
    color: $wb-autocomplete-dropdown-search-text-color;
    font-weight: $wb-fems-autocomplete-dropdown-search-font-weight;
    padding: $wb-autocomplete-dropdown-search-text-padding;
  }

  ul li {
    font-size: $wb-autocomplete-dropdown-item-font-size;
    line-height: $wb-autocomplete-dropdown-item-line-height;
    padding: $wb-autocomplete-dropdown-item-padding;

    &.token-input-dropdown-item {
      background-color: $wb-autocomplete-dropdown-item-stripped-bg-color;
    }

    &.token-input-selected-dropdown-item {
      background: $wb-autocomplete-dropdown-item-selected-bg-color;
      color: $wb-autocomplete-dropdown-item-selected-color;
    }
  }
}
