/**
 * @file
 *
 * This file contains bootstrap core
 * css styling overrides.
 *
 * @todo: Future plans -
 * Audit  the changes to filter out the styling
 * and incorporate basic color and spacing
 * changes inside core SSP bootstrap theme
 * So that these can be directly configured
 * through css variable for other sub themes.
 */
.page-header {
  margin-top: $line-height-computed;
}

.progress {
  height: #{$line-height-computed / 2};
}

.form-control {
  box-shadow: none;
}

/* stylelint-disable selector-max-compound-selectors */
table {
  > thead {
    > tr {
      > th {
        color: $ssp-brand-secondary-text-color;
      }
    }
  }
}
/* stylelint-enable */

.help-block {
  color: $ssp-brand-secondary-text-color;
}

.pagination {
  > li {
    > a,
    > span {
      padding: 6px 12px;
    }

    &:last-child,
    &:first-child {
      > a,
      > span {
        border-radius: 0;
      }
    }
  }
}

.nav-tabs {
  > li {
    > a {
      border-radius: 0;
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        border-bottom-color: $ssp-brand-primary;
      }
    }
  }
}

.dropdown-menu {
  @include box-shadow($ssp-dropdown-box-box-shadow);
}

.panel-heading {
  font-family: $ssp-panel-heading-font-family;
  font-size: $ssp-panel-heading-font-size;
  font-weight: $ssp-panel-heading-font-weight;
  line-height: $ssp-panel-heading-line-height;
  margin: $ssp-panel-heading-margin;

  .panel-title {
    font-size: inherit;
  }
}
