/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * Set placeholder user image
 */

@mixin user-image-placeholder(
  $height: 100%, $size:
  $ssp-user-image-placeholder-icon-font-size-small,
  $color: $ssp-user-image-placeholder-icon-color,
  $background: $ssp-user-image-placeholder-background
) {
  background-color: $ssp-details-page-no-image-background;
  font-size: 0;
  padding-top: $height;
  position: relative;

  &::before {
    align-items: center;
    bottom: 0;
    color: $color;
    font-size: $size;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    @include fa-icon($ssp-user-image-placeholder-icon);
  }

  img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
