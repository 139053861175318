/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * CiviCRM form elements overrides.
 */

/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable selector-max-id, max-nesting-depth, selector-max-compound-selectors, selector-no-qualifying-type*/

#crm-container {
  &.crm-public {
    .crm-form-radio,
    .crm-form-checkbox {
      .ssp-page-content & {
        clear: left;
        float: left;
        margin-top: $ssp-civicrm-multi-options-top-margin;

        + label {
          float: left;
          font-weight: $ssp-civicrm-multi-options-label-font-weight;
          margin-bottom: $ssp-civicrm-multi-options-label-bottom-margin;
        }
      }
    }

    .crm-form-entityref,
    .crm-select2,
    .crm-form-date-wrapper {
      max-width: $ssp-civicrm-dropdown-elements-max-width;
    }
  }
}
