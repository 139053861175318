/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

.webform-layout-box {
  &,
  &.horiz {
    > * {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.horiz {
    > * {
      width: 50%;

      &:nth-child(2n) {
        padding-right: 0;
      }

      &:nth-child(2n+1) {
        padding-left: 0;
      }
    }
  }

  &.equal {
    > * {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &:not(.vert) > * {
    padding-left: $wb-layout-box-field-spacing-x / 2;
    padding-right: $wb-layout-box-field-spacing-x / 2;

    @media (max-width: $screen-xs-max) {
      padding-left: 0;
      padding-right: 0;
      width: 100% !important;
    }
  }
}
