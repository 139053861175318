/**
 * Copyright © Compucorp Ltd 2022.
 * This software code is owned by Compucorp Ltd and is protected
 * by copyright. All rights reserved.
 * No part of this software code may be reproduced, stored in a
 * retrieval system of any nature, or transmitted, in any
 * form or by any means without the prior written permission
 * of Compucorp Ltd.
 * If any unauthorised acts are carried out in relation to this
 * copyright work, a civil claim for damages may be made against you.
 */

/**
 * @file
 *
 * My memberships block related styles
 */

.ssp-memberships-list {
  min-height: $ssp-memberships-block-min-height;
  padding: $ssp-memberships-list-block-padding !important;

  .ssp-memberships-list__title {
    margin-bottom: $ssp-block-title-bottom-margin !important;
  }

  .ssp-memberships-list__item {
    display: flex;
    flex-wrap: wrap;
    font-size: $ssp-memberships-list-item-font-size;
    line-height: $sc-paragraph-line-height-sm;
    padding: $ssp-memberships-list-item-padding;

    .ssp-memberships-list__membership-name {
      @include for-tablet-desktop-up() {
        min-width: $ssp-memberships-item-name-min-width;
      }
    }

    .ssp-memberships-list__current-icon {
      color: $ssp-brand-success;
    }

    .ssp-memberships-list__expired-icon {
      color: $ssp-brand-warning;
    }

    .ssp-memberships-list__end-date--active {
      color: $ssp-brand-success;
    }

    .ssp-memberships-list__end-date--expired {
      color: $ssp-brand-warning;
    }

    @include for-tablet-desktop-down() {
      justify-content: space-between;
    }
  }

  .ssp-memberships-list-separator {
    float: left;
    height: $ssp-memberships-list-separator;
    width: 100%;
  }

  .ssp-memberships-list__current,
  .ssp-memberships-list__expired {
    .ssp-memberships-list__item:not(:last-child) {
      border-bottom: $ssp-memberships-list-item-border;
    }
  }
}

.ssp-renewal-info {
  min-height: $ssp-memberships-block-min-height;
  text-align: center;

  .ssp-renewal-info--expired {
    color: $ssp-brand-warning;
  }

  .ssp-renewal-info__day {
    font-weight: $ssp-font-weight-bold;
  }

  .ssp-renewal-info__year {
    font-size: $ssp-memberships-renewal-info-year-size;
  }

  .ssp-renewal-info__alert {
    color: $ssp-brand-success;
    font-size: $ssp-memberships-renewal-info-year-size;
    padding: $ssp-memberships-renewal-info-spacing;
  }
}

.ssp-memberships-paid-by {
  h3 {
    padding-bottom: $ssp-memberships-block-message-spacing;
  }
}

.ssp-memberships-all-expired {
  display: flex;

  @include for-phone-only() {
    flex-flow: row wrap;
  }
}

.ssp-memberships-all-expired--icon {
  margin-right: $ssp-memberships-icon-margin-right;
  padding-top: $ssp-memberships-icon-padding-top;

  i {
    background-color: $ssp-brand-warning;
    border-radius: 50%;
    color: $ssp-brand-white;
  }
}

.ssp-memberships-all-expired--icon__font-size {
  font-size: $ssp-memberships-icon-font-size !important;
}

.ssp-memberships-all-expired--message {
  max-width: $ssp-memberships-block-message-max-width;
  padding-bottom: $ssp-memberships-block-message-spacing;
}

.ssp-switch-payment {
  display: flex;

  @include for-phone-only() {
    flex-flow: row wrap;
  }
}

.ssp-switch-payment--icon {
  margin-right: $ssp-memberships-icon-margin-right;
  padding-top: $ssp-memberships-icon-padding-top;

  i {
    border-radius: 50%;
  }
}

.ssp-switch-payment--icon__font-size {
  font-size: $ssp-memberships-icon-font-size !important;
}

.ssp-switch-payment--message {
  max-width: $ssp-memberships-block-message-max-width;
  padding-bottom: $ssp-memberships-block-message-spacing;

  p {
    margin: 0;
  }
}

.ssp-switch-payment--button,
.ssp-memberships-all-expired--button {

  align-self: center;

  @include for-tablet-portrait-up() {
    margin-left: auto;
  }
}

// Payment method page.
.page-ssp-my-memberships-payment-method {
  .ssp-payment-processor-to-prefix {
    font-size: $font-size-h4;
    font-weight: $ssp-font-weight-medium;
  }

  .ssp-payment-processor-from-label,
  .form-item-ssp-memberships-switch-payment-methods-payment-processors-to-options {
    float: right;
    min-width: $ssp-memberships-payment-method-min-width;

    span.form-element-label {
      font-size: $font-size-h4;
      font-weight: $ssp-font-weight-medium;
    }
  }

  .ssp-payment-processor-to-description {
    clear: right;
    margin-left: $ssp-memberships-payment-method-to-options-description-ml;
  }
}

// Payment confirmation screen.
.page-ssp-my-memberships-payment-method-confirmation {
  .payment-method-confirmation-list {
    min-height: $ssp-memberships-block-min-height;
    padding: $ssp-memberships-list-block-padding;

    .payment-method-confirmation-list__item {
      display: flex;
      flex-wrap: wrap;
      font-size: $ssp-memberships-list-item-font-size;
      line-height: $sc-paragraph-line-height-sm;
      padding: $ssp-memberships-list-item-padding;
    }

    .payment-method-confirmation-list__current-icon {
      color: $ssp-brand-success;
    }

    .payment-method-confirmation-list__description {
      color: $ssp-brand-gray;
    }

    @include for-tablet-desktop-down() {
      justify-content: space-between;
    }
  }
}

// Payment outstanding invoices.
.page-ssp-my-memberships-payment-method-outstanding-invoices {
  .outstanding-invoices-description {
    margin-bottom: $ssp-memberships-outstanding-invoices-description-mb;
  }

  table.outstanding-invoices-total-due-amount-table {

    float: right;
    margin: $ssp-memberships-outstanding-invoices-total-amount-table-margin;
    width: $ssp-memberships-outstanding-invoices-total-amount-table-width;

    td {
      padding-left: $ssp-memberships-outstanding-invoices-total-amount-table-td-pl;
    }

    td:first-child {
      width: $ssp-memberships-outstanding-invoices-total-amount-table-td-width;
    }
  }
}
